import React, { useEffect, useState } from "react";
import CaseStudyImg from "../../assets/images/CaseStudyImg.webp";
import Case1 from "../../assets/images/case-1.webp";
import Case2 from "../../assets/images/case-2.webp";
import Case3 from "../../assets/images/case-3.webp";
import Case4 from "../../assets/images/case-4.webp";
import Case5 from "../../assets/images/case-5.webp";
import Case6 from "../../assets/images/case-6.webp";
import { Link } from "react-router-dom";

const CaseStudyContent = () => {

  return (
    <div>
      <div className="container-fluid my-5 py-5" data-aos="fade-up" data-aos-delay="200">
        <div>
          <h2 className="section-heading text-center mb-5">
            Social Eagle operates in a five-element format.
          </h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <img className="w-100 h-auto" src={CaseStudyImg} alt=""></img>
          </div>
        </div>
      </div>
      <div className="section-gap" id="Techgyan" data-aos="fade-up" data-aos-delay="200">
        <div className="container">
          <div>
            <h2 className="section-heading text-center mb-5">Techgyan</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Overview</h4>
                <p className="founder-para">
                  The Goal was to acquire customers in order to market a summer
                  program driven by an advanced learning management system to
                  learn technical skills that would aid subscribers in their
                  career development.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Target audience</h4>
                <p className="founder-para">Our target audience for the project was 18-25 year olds of all genders with relevant interest matches.</p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Achievement</h4>
                <p className="founder-para">As a result, we gained 300+ new customers while spending 5,00,000 INR and achieving a ROAS of 25,00,000 INR.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="dimg-container">
                <img src={Case1} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap" id="ECGAcademy" data-aos="fade-up" data-aos-delay="200">
        <div className="container">
          <div>
            <h2 className="section-heading text-center mb-5">ECG Academy</h2>
          </div>
          <div className="row flex-direct-mob">
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="dimg-container">
                <img src={Case2} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Overview</h4>
                <p className="founder-para">
                  The Goal was to gain customers in order to market the ECG
                  academy learning system to medical professionals.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Target audience</h4>
                <p className="founder-para">Our target audience for the project were male and females aged 24 and up who are medical students, nurses, nursing students, lab technicians, and hospital staff.</p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Achievement</h4>
                <p className="founder-para">The ad campaign resulted in multiple subscriptions from the target audience.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap" id="SkinPotco" data-aos="fade-up" data-aos-delay="200">
        <div className="container">
          <div>
            <h2 className="section-heading text-center mb-5">Skin Pot co</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Overview</h4>
                <p className="founder-para">
                  The Goal was to acquire customers for their skin care physical
                  product "Breast and Bums Butter", so we campaigned for their
                  best combo offer of "buy two get one free".
                </p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Target audience</h4>
                <p className="founder-para">Our project's target audience included male and females aged 12 to 65 who were interested in skincare and beauty.</p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Achievement</h4>
                <p className="founder-para">As a result of the campaign, conversion rates increased, allowing them to double their revenue in 30 days. Returning customers were 9%, and their ROAS was tripled in less than 30 days.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="dimg-container">
                <img src={Case3} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap" id="YCBAcademy"  data-aos="fade-up" data-aos-delay="200">
        <div className="container">
          <div>
            <h2 className="section-heading text-center mb-5">YCB Academy</h2>
          </div>
          <div className="row flex-direct-mob">
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="dimg-container">
                <img src={Case4} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Overview</h4>
                <p className="founder-para">
                  The Goal was to acquire customers for a digital product in
                  order to book qualified appointments for a weight loss
                  program.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Target audience</h4>
                <p className="founder-para">The target audience consisted of men aged 35 and up who did not have time to go to the gym or care for their bodies through healthy eating habits.</p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Achievement</h4>
                <p className="founder-para">As a result of the campaign, the client's calendar was flooded with appointments, increasing revenue by 7x.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap" id="BlissPads" data-aos="fade-up" data-aos-delay="200">
        <div className="container">
          <div>
            <h2 className="section-heading text-center mb-5">Bliss Pads</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Overview</h4>
                <p className="founder-para">
                  The Goal was to acquire customers for a physical product,
                  sanitary napkins, promoted their combo packs.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Target audience</h4>
                <p className="founder-para">The target audience consisted of working women between the ages of 18 and 45 who were interested in natural products and hygiene.</p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Achievement</h4>
                <p className="founder-para">The campaign resulted in a spending of 20,000 INR and a ROAS of 1,00,000 INR in less than 30 days. In addition, customer retention increased by 77%.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="dimg-container">
                <img src={Case5} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap" id="RichTwin" data-aos="fade-up" data-aos-delay="250">
        <div className="container">
          <div>
            <h2 className="section-heading text-center mb-5">Rich Twin financial program</h2>
          </div>
          <div className="row flex-direct-mob">
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="dimg-container">
                <img src={Case6} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Overview</h4>
                <p className="founder-para">
                  The Goal was to gain customers for a digital product, through a
                  5-day live workshop on 'how to manage money like a millionaire'
                  without taking any risks.
                </p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Target audience</h4>
                <p className="founder-para">The target audience consists of male and female candidates aged 25 and up who are interested in financial education, investment, stocks, mutual funds, and trading.</p>
              </div>
              <div className="mb-4">
                <h4 className="color-sec-head mb-2  fs-3">Achievement</h4>
                <p className="founder-para">The campaign resulted in 1000+ paid workshop registrations with a strong second level offer and a revenue of 10,00000 INR.</p>
              </div>
            </div>
          </div>

          <div className="text-center">
            <h2 className="section-heading text-center mt-5 fs-3">Your business can be one of our next success stories</h2>
            <Link to="/ContactUs">
            <button type="button" className="btn mt-3 btn-primary contact-btn"
            >
              Ready to Get Featured?
            </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyContent;
