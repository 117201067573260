import React from "react";
import BlogImg4 from "../../assets/images/blog-img4.webp";
import { Link } from "react-router-dom";
export const ColdEmailing = () => {
  return (
    <div>
      <div>
        <div
          className="bg-video-wrap2 blog-details-banner"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="home-video-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <h1 className="banner-heading mb-2 text-uppercase">
                    COLD EMAILING SECRETS
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-5 section-gap"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="container">
            <div className="row ">
              <div className="col-lg-12">
                <div className="blog-content">
                  <div className="row">
                    <div className="col-lg-8">
                      <img src={BlogImg4} className="w-100 h-auto"></img>
                    </div>
                  </div>
                  <div className="d-flex my-3 align-items-center">
                    <span className="me-2">
                      <i class="fa-regular text-secondary fa-calendar-days"></i>
                    </span>
                    <p className="text-secondary mb-0">12 Aug 2022</p>
                  </div>
                  <h2 className="font-bold mb-4">COLD EMAILING SECRETS</h2>
                  <div className="">
                    <p>
                      Learn The 6 Cold Emailing secrets That Every Business
                      Owners Pro Knows, But You Dont
                    </p>
                    <p>
                      Have you been getting a ton of emails lately from
                      businesses talking about the Coronavirus?
                    </p>
                    <p>
                      My inbox is bursting at the seams, and I’m sure yours is
                      too.
                    </p>
                    <p>
                      And 99% percent of the time, this is what the email sounds
                      like:{" "}
                      <span className="font-bold">
                        {" "}
                        “Hey, I know you’ve never heard of me, but I’m reaching
                        out because my business is doing bad and I want your
                        money!”{" "}
                      </span>
                    </p>
                    <p>
                      That’s because most people do cold email completely wrong.
                    </p>
                    <p>
                      But what if I told you that I have been generating crazy
                      results with cold email?
                    </p>
                    <p>
                      In my business (because a lot of them run their own
                      agencies), I’ve been sending cold emails and I’ve been
                      surprised on the fact that I was able to book meeting with
                      businesses.
                    </p>
                    <p>
                      In this post, I’m going to walk you through exactly how to
                      write a killer cold email. It’s not the way to do it, but
                      this is the formula that wor only ks for me.
                    </p>
                    <h4 className="text-center font-bold mb-3">OUTFLOW = INCOME</h4>
                    <p>
                      Before we get into the formula, I want to share an
                      incredibly important lesson.
                    </p>
                    <p>
                      The economy is in chaos right now, and if you want more
                      customers you can’t wait for them to come to you. You have
                      to take the initiative: go nd prospects and put your offer
                      in front of them.
                    </p>
                    <p>
                      So many people buy into this pipe dream that the “internet
                      gurus” like to sell: that you can run a few Facebook ads,
                      or make a few social media posts that go viral, and
                      suddenly money will come flooding in.
                    </p>
                    <p>
                      Well if that didn’t work while the economy was great, what
                      are the chances that sales will start streaming in now
                      that the economy is in crisis?
                    </p>
                    <p>
                      The truth is, online business is still... business. And
                      all the same rules apply.
                    </p>
                    <p>
                      So while the wantrepreneurs are frantically trying to gure
                      out how to survive this storm, you can go on the
                      offensive. And that’s where cold email comes in.
                    </p>
                    <p>With that said, let’s jump into the formula...</p>
                    <p>STEP 1: Explain why are you Reaching out!</p>
                    <p>
                      Most people think,
                      <span className="font-bold">
                        {" "}
                        “My ideal clients are Coaches and Consultants, so I’m
                        going to get a list of all the Coaches in the country
                        and email them.”
                      </span>
                    </p>
                    <p>
                      Here’s the problem with that strategy. When a name someone
                      has never seen before pops up in their inbox, their
                      defenses are immediately going to go up.
                    </p>
                    <p>
                      You have to be able to give them a good reason why you
                      sent an email. And if that reason is,
                      <span className="font-bold">
                        “I found a list of Coaches or consultants and I want to
                        sell you something...”
                      </span>
                      that’s not a very good reason. It feels impersonal and
                      insincere and they’re going to immediately hit delete.
                    </p>
                    <p>
                      What I like to do is come up with a strategy that is a
                      good excuse for reaching out. For example, I’ll finnd a
                      blog or podcasts or video that interviews coaches. Then
                      I’ll nd the email address for all of the guests and I’ll
                      reach out and say,
                      <span className="font-bold">
                        “Hi, my name is Vishnu and I heard/read you on the XYZ
                        podcast/blog/video. I just wanted to say thank you
                        because I learned _____ from you and it’s been so
                        helpful!”
                      </span>
                    </p>
                    <p>
                      Another example I had success with is reaching out to
                      businesses that are running social media ads. If I see an
                      Instagram ad or a Facebook ad, that’s a great sign that
                      they’re spending money to grow their business. So I DM
                      them and say,{" "}
                      <span className="font-bold">
                        {" "}
                        “Hi, I just saw one of your ads. I clicked through to
                        your feed and I love your content! I especially like the
                        post about _____.”
                      </span>{" "}
                      I like to think about each of these “excuses” as a pond
                      full of fish. Rather than just emailing all Coaches, try
                      to find a subset of coaches that are connected by a
                      podcast/video/blog,or an advertising channel, or a piece
                      of software, or an event they all attend. Now you can
                      create a “hook” that works specifically for that pond, and
                      you can target all of the fish without their defences
                      going up.
                    </p>
                    <p>STEP 2: Show that you’re familiar with their business</p>
                    <p>
                      This gives the message that, “I’m not reaching out because
                      I found your email address and I want to pitch you. I’m
                      reaching out because I discovered you in an organic way,
                      I’m familiar with you and your business, and I learned
                      something about you specifically that made me think I can
                      provide value.”
                    </p>
                    <p>
                      It’s like the difference between someone coming up to you
                      at a party and calling you by your first name, versus
                      yelling, “Hey you!”
                    </p>
                    <p>And there’s one more important benefit too...</p>
                    <p>
                      Imagine your subject line is:
                      <span className="font-bold">
                        "Can I help you grow your business?"
                      </span>{" "}
                      It’s an immediate red flag that you’re going to pitch
                      them, and they’re going to delete the email before they
                      even open it.
                    </p>
                    <p>
                      But what if the subject line is:{" "}
                      <span className="font-bold">
                        "Thanks for your podcast/video/blog interview!"
                      </span>{" "}
                      The person thinks: Is it a compliment? Is it feedback from
                      a fan?
                    </p>
                    <p>
                      They spent their time and energy sharing their knowledge
                      on the podcast/video, so they can’t help but open the
                      email.
                    </p>
                    <p>
                      Or what if the subject line is:
                      <span className="font-bold">
                        {" "}
                        "One of your Ad just popped up in my feed!"{" "}
                      </span>
                      They think: what could this possibly be? Did they see the
                      ad and now they want to buy? Are they annoyed that they
                      saw my ad?
                    </p>
                    <p>Once again they can’t help but open your email.</p>
                    <p>
                      STEP 3: Briefly touch on the pain point, but quickly
                      transition to the pain point.
                    </p>
                    <p>
                      Next I like to introduce the problem. I usually say
                      something like:
                    </p>
                    <p className="font-bold">
                      “I know how hard it is to do social media marketing,
                      especially when it takes so much time and it’s almost
                      impossible to track the ROI.”
                    </p>
                    <p className="font-bold">
                      or “You’ve probably heard business gurus talk about how
                      important an email list is, but you assume it doesn’t
                      apply to you because you’re a brick and mortar business.”
                    </p>
                    <p>
                      I write one sentence to show that I understand their
                      industry and what they’re going through. But it also does
                      something deeper: it touches that emotional trigger so
                      they think,
                      <span className="font-bold">
                        “You’re right, this is a big problem in my business. I
                        need to solve this, and I want that pain to go away.”
                      </span>{" "}
                    </p>
                    <p>
                      And here’s an alternative: sometimes I’ll also use the{" "}
                      <span className="font-bold">“ appeal to authority"</span>{" "}
                      strategy.
                    </p>
                    <p>
                      Here’s what I mean: let’s say there’s a famous Coach that
                      writes books and gives speeches, teaching Coaches how to
                      grow their practices. I’ll say something like,
                      <span className="font-bold">
                        {" "}
                        “You’ve probably heard So- And-So teach how to
                        accomplish XYZ, but you realize it takes a ton of time
                        and energy to implement his strategy.”{" "}
                      </span>
                    </p>
                    <p>
                      So this touches on the pain point as well- they don’t have
                      the time and resources to do all the things they should to
                      grow their practice. But it does another powerful thing:
                      it lets you piggyback off of a famous person’s expertise.
                    </p>
                    <p>
                      Maybe they’ve never heard of you before, but they have
                      heard of the person you’re referencing. So now you get to
                      say, “I’m not selling you my knowledge and my expertise.
                      I’m selling the expertise of someone you already respect.”
                    </p>
                    <p>
                      STEP 4: Tease the benefits of your service, but skip the
                      details
                    </p>
                    <p>
                      After I use the problem to set up the need for my service,
                      I give one sentence explaining what it does.
                    </p>
                    <p>
                      And here’s the key: I don’t say anything about HOW my
                      service does it.
                    </p>
                    <p>
                      So for example, I’ll say something like:
                      <span className="font-bold">
                        {" "}
                        “I let you publish one post on Facebook every single day
                        without you having to ever log into Facebook again.”
                      </span>
                      – Referring to my micro service.
                    </p>
                    <p>
                      Or, “I’ll turn your email list into a new income stream
                      without you having to send a single email.”
                    </p>
                    <p>
                      Or, “I’ll help create a book with all your blogs that
                      serves as a lead magnet for you to attract more clients or
                      even sell it and make money passive without your active
                      participation.”
                    </p>
                    <p>
                      I like the.
                      <span className="font-bold">
                        {" "}
                        “massive benefit minus painful objection”
                      </span>{" "}
                      formula You tease a result the client really wants, but
                      add a surprising twist so they think, “How can they
                      possibly do that?!”
                    </p>
                    <p>And that’s all you say about your service. Don’t tell them how it works, don’t say who it’s for, don’t tell them how much it costs... just leave them thinking, “Wow, that’s a bold claim. Could it possibly be true?”</p>
                    <p>STEP 5: Open the Door</p>
                    <p>Next simply ask, <span className="font-bold"> “Is that something that would help grow your business? If so, can I answer any questions for you or send any samples?”</span> </p>
                    <p>In most cases, if you’ve made your service intriguing enough, this makes it super easy for the prospect to say,<span className="font-bold"> “Sure, send me more.”</span></p>
                    <p>Now the conversation is open and you can tell them more about your service.</p>
                    <p>STEP 6: The follow-up</p>
                    <p>Here’s the part that most people completely miss: don’t stop at just one email!</p>
                    <p>Often the first email might slip through the cracks. You’ve already done the hard work of Fing the pond and finding the person’s email address, so don’t let your hard work go to waste.<span className="font-bold"> Send a follow up (or two or three) </span>.
</p>
                    <p>And here’s an important tip: even though you’re following up, don’t use the term “follow-up”!</p>
                    <p>Saying “I’m just following up” sounds a lot like, <span className="font-bold">“My first email wasn’t valuable enough for you to reply to, and now I’m asking you to go back and read it again.” </span> </p>
                    <p>Instead, I like to say,<span className="font-bold"> “A couple weeks ago I emailed you about XYZ. I wanted to tell you something else I think you’d love to know about...” </span></p>
                    <p>Do it in a way where you’re providing fresh information, and if it all possible give value. Share a little piece of information that is useful to them. Maybe include links to work examples that will help them make their decision. Or give samples that they can start using for themselves right away.</p>
                    <p>I usually use a spreadsheet to track all of our outreach to make sure people don’t fall through the cracks.</p>
                    <p>You can Automate these using MailShake or any email automation tool.</p>
                    <p>I hope you learned something from this PDF. Now I’d love to do something fun: if you want to start using cold email to grow your business, please leave your 3×2 email in the comments below the unit in the group <span className="font-bold"> (Business Growth W/O Spending on unwanted Ads!) - Facebook </span></p>
                    <p>Let’s give each other feedback and help each other write killer cold emails.</p>
                    <p>And then when you start getting leads and sales, come back and share your results in the group</p>
                    <p><a href="https://www.facebook.com/groups/scalemybusiness" target="_blank">https://www.facebook.com/groups/scalemybusiness</a>- (Where you can learn about growing your business without spending much on ads)</p>
                    <p>Now let’s get to work!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-gap text-center">
          <Link to="/ContactUs">
            <button type="button" className="btn btn-primary contact-btn">
              Get In Touch
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
