import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import CaseStudyContent from "../Components/CaseStudy/CaseStudyContent";
import CaseStudyBanner from "../Components/CaseStudy/CaseStudyBanner";

const CaseStudy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <CaseStudyBanner/>
      <CaseStudyContent />
      <Footer />
    </div>
  );
};

export default CaseStudy;
