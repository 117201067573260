import React from "react";
import Video from "../../assets/images/si-video-2.mp4";
import { Link, useNavigate } from "react-router-dom";
export const HomeBanner = () => {
    const navigation = useNavigate();
  return (
    <>
      <div className="bg-video-wrap2">
        <video autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>

        <div className="overlay"></div>
        <div className="home-video-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="banner-heading">Want To Get More Customers From The Internet?</h1>
                <p className="text-white text-center text-capitalize mb-0">
                  Build a brand that is data-driven, performance-based, customer-led, with <br /> holistic strategies (basically increasing 10X in every aspect of your venture).
                  <br />
                  Whether you're big or small, we're here to work for you or with you,
                  <br />
                  every step of the way.
                </p>
                <div className="text-center">
                  <button
                    type="button"
                    onClick={() => {
                      navigation("/ContactUs");
                    }}
                    className="btn mt-5 btn-primary contact-btn"
                  >
                    Fly With Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
