import React from "react";
import BlogImg1 from "../../assets/images/blog-img1.webp";
import BlogImg2 from "../../assets/images/blog-img2.webp";
import BlogImg3 from "../../assets/images/blog-img3.webp";
import BlogImg4 from "../../assets/images/blog-img4.webp";
import BR1 from "../../assets/images/br1.webp";
import BR2 from "../../assets/images/br2.webp";
import BR3 from "../../assets/images/br3.webp";
import BR4 from "../../assets/images/br4.webp";
import BR5 from "../../assets/images/br5.webp";
import BR6 from "../../assets/images/br6.webp";
import BR7 from "../../assets/images/br7.webp";
import BR8 from "../../assets/images/br8.webp";
import BR9 from "../../assets/images/br9.webp";
import BR10 from "../../assets/images/br10.webp";
import BR11 from "../../assets/images/br11.webp";
import BR12 from "../../assets/images/br12.webp";
import { Link, useNavigate } from "react-router-dom";
export const BlogsContent = () => {
  const navigate = useNavigate();

  const recentBlogs = [
    {
      img: BR2,
      heading: "The Complete Digital Marketing Course 12 – Courses in 1",
      name: "Dharaneetharan G D",
      date: "August 25, 2023",
      path: "/TheCompleteDigitalMarketingCourse12CoursesIn1",
    },
    {
      img: BR4,
      heading: "Six Pillars of Digital Marketing",
      name: "Dharaneetharan G D",
      date: "August 16, 2023",
      path: "/SixPillarsofdigitalMarketing",
    },
    {
      img: BR6,
      heading: "How to start a Digital Marketing Agency",
      name: "Dharaneetharan G D",
      date: "July 25, 2023",
      path: "/HowtoStartaDigitalMarketingAgency",
    },
    {
      img: BR8,
      heading: "Top Digital Marketing Project Ideas (2023)",
      name: "Dharaneetharan G D",
      date: "July 24, 2023",
      path:"/DigitalMarketingProjects",
    },
    {
      img: BR10,
      heading: "Digital Marketing Course After 10th",
      name: "Dharaneetharan G D",
      date: "July 24, 2023",
      path:"/DigitalMarketingCourseAfter10th",
    },
    {
      img: BR1,
      heading: "MBA Marketing Project Topics",
      name: "Dharaneetharan G D",
      date: "July 24, 2023",
      path:"/MBAMarketingProjectTopics",
    },
    {
      img: BR3,
      heading: "How to become a Digital Marketing Specialist",
      name: "Dharaneetharan G D",
      date: "June 26, 2023",
      path:"/DigitalMarketingSpecialist",
    },
    {
      img: BR5,
      heading: "Types of Digital Marketing: A Comprehensive Guide",
      name: "Dharaneetharan G D",
      date: "June 24, 2023",
      path:"/TypesofDigitalMarketing",
    },
    {
      img: BR7,
      heading: "Digital Marketing Trends: Insights for Success",
      name: "Dharaneetharan G D",
      date: "June 22, 2023",
      path:"/DigitalMarketingTrends",
    },
    {
      img: BR9,
      heading: "Scope of Digital Marketing",
      name: "Dharaneetharan G D",
      date: "June 20, 2023",
      path:"/ScopeofDigitalMarketing"
    },
    {
      img: BR11,
      heading: "Digital Marketing Course After 12th",
      name: "Dharaneetharan G D",
      date: "June 18, 2023",
      path:"/DigitalMarketingCourseAfter12th",
    },
    {
      img: BR12,
      heading: "Digital Marketing Jobs for freshers: Explore the Opportunity",
      name: "Dharaneetharan G D",
      date: "June 16, 2023",
      path:"/DigitalMarketingJobsforFreshers",
    },
  ];
  return (
    <div>
      <div className="mt-5 section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="blog-content mb-5">
                <img src={BlogImg1} className="w-100 h-auto"></img>
                <div className="d-flex my-3 align-items-center">
                  <span className="me-2">
                    <i class="fa-regular text-secondary fa-calendar-days"></i>
                  </span>
                  <p className="text-secondary mb-0">12 Aug 2022</p>
                </div>
                <h2 className="font-bold mb-4">
                  The Recession is Beneficial!🤩
                </h2>
                <div className="">
                  <p>
                    Isn't it shocking...?😳 <br />
                    There are, however, many benefits associated with …...
                    recessions...
                  </p>
                  <p>
                    There are, however, many benefits associated with recessions
                    compared to their negative effects!{" "}
                  </p>
                  <button
                    type="button"
                    className="btn px-4 contact-btn"
                    onClick={() => {
                      navigate("/TheRecessionisBeneficial");
                    }}
                  >
                    Explore
                  </button>
                </div>
              </div>
              <div className="blog-content mb-5">
                <img src={BlogImg2} className="w-100 h-auto"></img>
                <div className="d-flex my-3 align-items-center">
                  <span className="me-2">
                    <i class="fa-regular text-secondary fa-calendar-days"></i>
                  </span>
                  <p className="text-secondary mb-0">12 Aug 2022</p>
                </div>
                <h2 className="font-bold mb-4">
                  6 steps to become a Crorepati
                </h2>
                <div className="">
                  <p>
                    Why should one be motivated to work toward being a
                    crorepati?
                  </p>
                  <p>
                    When one considers the current rate of inflation, it is
                    impossible not to speculate about the potential economic
                    collapse that lies ahead of us in the not-too-distant
                    future.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      className="btn px-4 contact-btn"
                      onClick={() => {
                        navigate("/SixStepstoBecomeaCrorepati");
                      }}
                    >
                      Explore
                    </button>
                  </div>
                </div>
              </div>
              <div className="blog-content mb-5">
                <img src={BlogImg3} className="w-100 h-auto"></img>
                <div className="d-flex my-3 align-items-center">
                  <span className="me-2">
                    <i class="fa-regular text-secondary fa-calendar-days"></i>
                  </span>
                  <p className="text-secondary mb-0">12 Aug 2022</p>
                </div>
                <h2 className="font-bold mb-4">The Law of Wasted Efforts</h2>
                <div className="">
                  <p>
                    Lions only succeed in a quarter of their hunting attempts —
                    which means they fail in 75% of their attempts and succeed
                    in only 25% of them.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      className="btn px-4 contact-btn"
                      onClick={() => {
                        navigate("/TheLawofWastedEfforts");
                      }}
                    >
                      Explore
                    </button>
                  </div>
                </div>
              </div>
              <div className="blog-content mb-5">
                <img src={BlogImg4} className="w-100 h-auto"></img>
                <div className="d-flex my-3 align-items-center">
                  <span className="me-2">
                    <i class="fa-regular text-secondary fa-calendar-days"></i>
                  </span>
                  <p className="text-secondary mb-0">12 Aug 2022</p>
                </div>
                <h2 className="font-bold mb-4">Cold Emailing Secrets</h2>
                <div className="">
                  <p>
                    Learn The 6 Cold Emailing secrets That Every Business Owners
                    Pro Knows, But You Dont
                  </p>
                  <p>
                    Have you been getting a ton of emails lately from businesses
                    talking about the Coronavirus?
                  </p>
                  <p>
                    My inbox is bursting at the seams, and I’m sure yours is
                    too.
                  </p>
                  <div className="">
                    <button
                      type="button"
                      className="btn px-4 contact-btn"
                      onClick={() => {
                        navigate("/ColdEmailingSecrets");
                      }}
                    >
                      Explore
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="">
                <h2 className="font-bold mb-4">Recent Post</h2>
                {recentBlogs.map((item, index) => {
                  return (
                    <Link to={item.path} className=" text-black" key={index}>
                      <div className="row mb-4">
                        <div className="col-lg-4 col-4">
                          <img
                            className="w-100 h-auto"
                            src={item.img}
                            alt=""
                          ></img>
                        </div>
                        <div className="col-lg-8 col-8">
                          <div>
                            <h6 className="font-bold">{item.heading}</h6>
                            <p className="mb-0 text-secondary">{item.name}</p>
                            <p className="mb-0 text-secondary small">
                              {item.date}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
