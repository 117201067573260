import React, { useEffect } from "react";
import Contact from "../Components/Contact/Contact";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { ContactBanner } from "../Components/Contact/ContactBanner";

const ContactUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <ContactBanner/>
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactUs;
