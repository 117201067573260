import React, { useEffect } from "react";
import { Navbar } from '../Components/Navbar/Navbar'
import { ToursandWorkshopsBanner } from '../Components/WorkWithUs/ToursandWorkshopsBanner'
import { ToursandWorkshopsContent } from '../Components/WorkWithUs/ToursandWorkshopsContent'
import { Footer } from "../Components/Footer/Footer";
export const ToursandWorkshops = () => {
    useEffect(() => {
        window.scroll(0, 0);
      }, []);
  return (
    <div>
        <Navbar/>
        <ToursandWorkshopsBanner/>
        <ToursandWorkshopsContent/>
        <Footer />
    </div>
  )
}
