import React, { useState } from "react";
import AsSeenOn from "../../assets/images/asseenon.webp";
import W1 from "../../assets/images/w-1.webp";
import W2 from "../../assets/images/w-2.webp";
import W3 from "../../assets/images/w-3.webp";
import W4 from "../../assets/images/w-4.webp";
import QuoteImg from "../../assets/images/quote.webp";
import AdvisoryModal from "./AdvisoryModal";
export const AdvisoryContent = () => {
  const [open, setOpen] = useState();
  const business = [
    {
      image: W1,
      heading: "Battle-Tested Results:",
      para: "Multiple successful transformations.",
    },
    {
      image: W2,
      heading: "Customer-Centric Approach:",
      para: "Tailored strategies for your Business.",
    },
    {
      image: W3,
      heading: "Proven Expertise:",
      para: "10+ years of industry experience.",
    },
    {
      image: W4,
      heading: "Continuous Support:",
      para: "Guiding you towards sustained success.",
    },
  ];
  return (
    <div>
      <div className="wmo mt-5 section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="section-heading text-center">
                Through Advisory <br />
                <span className="color-sec-head">we redefine consulting</span>
              </h2>
              <p className="para-color res-font text-center">
                We Believe Real results happen when you improve the  People's side and the Business's side together.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="position-relative">
            <hr className="asseen-border" />
            <div className="asseenon-content ">
              <h4 className="color-sec-head fst-italic bg-white px-3 mb-0 ">As Seen on</h4>
            </div>
          </div>
          <div className="container-fluid">
            <img className="w-100 h-auto" src={AsSeenOn} alt=""></img>
          </div>
          <hr className="asseen-border" />
        </div>
        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="text-center">
                <h4 className="font-bold fst-italic  bg-white px-3 mb-4 ">
                  Save Your Time by <span className="color-sec-head fst-normal font-extrabold fs-1 ">2X</span> and Grow by <span className="color-sec-head fst-normal font-extrabold fs-1 ">10X</span>
                </h4>
                <p className="para-color res-font text-center">
                  We believe success happens when Technical Knowledge 
                  and Domain Knowledge blend together.<br /> Because no agency can dominate every domain.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="data-play-head-bg">
          <div className="container">
            <div className="Data plays an important role">
              <h4 className="text-white font-bold text-center py-4 text-capitalize">Domain knowledge + Marketing + Branding + Positioning = SCALING</h4>
            </div>
          </div>
        </div>
        <div className="container text-center py-4">
          <h4 className="font-bold fst-italic  bg-white px-3 mb-5 ">
            “Because growing
            <span className="color-sec-head fst-normal font-extrabold fs-1 ">{" "}10X</span> is faster than <span className="color-sec-head fst-normal font-extrabold fs-1 ">2X</span>
          </h4>
          <div>
            <button
              onClick={() => {
                setOpen(true);
              }}
            
              type="button"
              className="btn btn-primary contact-btn"
            >
              Ready to Scale Your Business?
            </button>
          </div>
        </div>
      </div>
      <div className="section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <h2 className="section-heading text-center">
            How do <span className="color-sec-head">we help businesses </span>
            like you?
          </h2>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="business-card">
                    <h5 className="section-heading lh-base  fs-4">
                      {" "}
                      <span className="color-sec-head">Digital Transformation:</span> <br />
                      Unleash Your Digital <br /> Potential
                    </h5>
                    <p className="res-font">
                      <span className="font-bold">
                        Results: <br />
                      </span>
                      68% Average Revenue Growth <br />
                      post-transformation.
                    </p>
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="button"
                      className="btn mt-2 btn-primary contact-btn px-4"
                    >
                      Transform My Business
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="business-card">
                    <h5 className="section-heading lh-base  fs-4">
                      {" "}
                      <span className="color-sec-head">Digital Asset Creation:</span> <br />
                      Craft Impactful Digital <br /> Presences
                    </h5>
                    <p className="res-font">
                      <span className="font-bold">
                        Results: <br />
                      </span>
                      45% increase in Customer <br />
                      Engagement.
                    </p>
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="button"
                      className="btn mt-2 btn-primary contact-btn px-4"
                    >
                      Craft My Digital Impact
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="business-card">
                    <h5 className="section-heading lh-base  fs-4">
                      {" "}
                      <span className="color-sec-head">
                        Long-Term Real Estate <br /> Wealth Creation:
                      </span>{" "}
                      <br />
                      Maximizing Returns
                    </h5>
                    <p className="res-font">
                      <span className="font-bold">
                        Results: <br />
                      </span>
                      25% Annualized Growth in <br /> Real Estate Portfolios.
                    </p>
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="button"
                      className="btn mt-2 btn-primary contact-btn px-4"
                    >
                      Unlock Real Estate Wealth
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="business-card">
                    <h5 className="section-heading lh-base  fs-4">
                      {" "}
                      <span className="color-sec-head">Process Automation:</span> <br />
                      Efficiency <br /> Unleashed
                    </h5>
                    <p className="res-font">
                      <span className="font-bold">
                        Results: <br />
                      </span>
                      60% Reduction in <br />
                      operational costs.
                    </p>
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="button"
                      className="btn mt-2 btn-primary contact-btn px-4"
                    >
                      Automate My Processes
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="business-card">
                    <h5 className="section-heading lh-base  fs-4">
                      {" "}
                      <span className="color-sec-head">
                        Digital Marketing <br /> Strategy:
                      </span>{" "}
                      <br />
                      Driving Growth
                    </h5>
                    <p className="res-font">
                      <span className="font-bold">
                        Results: <br />
                      </span>
                      3x Increase in Online <br />
                      Leads.
                    </p>
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="button"
                      className="btn mt-2 btn-primary contact-btn px-4"
                    >
                      Fuel My Growth
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="business-card">
                    <h5 className="section-heading lh-base  fs-4">
                      {" "}
                      <span className="color-sec-head">
                        High-Converting Sales <br /> Strategy:
                      </span>{" "}
                      <br />
                      Amplifying Sales
                    </h5>
                    <p className="res-font">
                      <span className="font-bold">
                        Results: <br />
                      </span>
                      40% Boost in <br />
                      Conversion Rates.
                    </p>
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="button"
                      className="btn mt-2 btn-primary contact-btn px-4"
                    >
                      Elevate My Sales
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="business-card">
                    <h5 className="section-heading lh-base  fs-4">
                      {" "}
                      <span className="color-sec-head">
                        Establishing Useful <br /> KPIs:
                      </span>{" "}
                      <br />
                      Metrics that Matter
                    </h5>
                    <p className="res-font">
                      <span className="font-bold">
                        Results: <br />
                      </span>
                      20% increase in ROI <br />
                      tracking strategic KPIs.
                    </p>
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="button"
                      className="btn mt-2 btn-primary contact-btn px-4"
                    >
                      Optimize My Success
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="business-card">
                    <h5 className="section-heading lh-base  fs-4">
                      {" "}
                      <span className="color-sec-head">
                        Implementing <br /> Innovative Ideas:
                      </span>{" "}
                      <br />
                      Propelling Growth
                    </h5>
                    <p className="res-font">
                      <span className="font-bold">
                        Results: <br />
                      </span>
                      55% rise in market share <br /> through Innovation.
                    </p>
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      type="button"
                      className="btn mt-2 btn-primary contact-btn px-4"
                    >
                      Propel My Business
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <h2 className="section-heading text-center mb-4">
            Why do <span className="color-sec-head">businesses Choose us?</span>
          </h2>
          <div className="row justify-content-center ">
            <div className="col-lg-10">
              <div className="row">
                {business.map((item, index) => {
                  return (
                    <div className="col-lg-6 col-md-6" key={index}>
                      <div className="business-card-2">
                        <img className="number-design mb-3" src={item.image} alt=""></img>
                        <h5 className="font-bold">{item.heading}</h5>
                        <hr className="red-half-line" />
                        <h5>{item.para}</h5>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="approch-section py-5 section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <h2 className="section-heading position-relative fs-1 text-center mb-3">
                <span className="color-sec-head hr-lines">Our Approach</span>
              </h2>
            </div>
          </div>
          <p className="para-color res-font text-center mb-4">Changing Your Business with SPEED & SCALE</p>
          <div className="row justify-content-center ">
            <div className="col-lg-7">
              <div className="d-flex ">
                <img className="bi-quote me-lg-4 me-2" src={QuoteImg} alt=""></img>
                <h5 className="font-bold fst-italic lh-base text-center font-small-approach-mob">
                  We don’t just provide advice; we initiate transformation. <br /> Our strategies drive change swiftly and at scale, ensuring <br /> your business leads rather than just adapts.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-5 section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <h2 className="section-heading fs-1 text-center mb-4">
            Ready to <span className="color-sec-head">Propel Your Business?</span>
          </h2>
          <div className="row justify-content-center ">
            <div className="col-lg-9">
              <div>
                <h5 className=" lh-base text-center mb-4">
                  Take the leap towards success. Collaborate with us to transform your
                  business, achieve growth, and surpass milestones together.
                </h5>
                <h5 className=" lh-base text-center">Contact us now to start your business transformation.</h5>
                <div className="text-center">
                  <button
                    onClick={() => {
                      setOpen(true);
                    }}
                    type="button"
                    className="btn mt-4 btn-primary contact-btn"
                  >
                    Transform My Business Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdvisoryModal
        open={open}
        setClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
