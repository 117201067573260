import React from 'react'

export const BlogsBanner = () => {
  return (
    <div className="bg-video-wrap2 blogs-banner" data-aos="fade-up" data-aos-delay="150">
      <div className="home-video-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h1 className="banner-heading mb-2 text-uppercase">Blogs</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
