import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Logo from "../../assets/images/logo.webp";
import Navs1 from "../../assets/images/navs-1.webp";
import Navs2 from "../../assets/images/navs-2.webp";
import Navs3 from "../../assets/images/navs-3.webp";
import Typewriter from "typewriter-effect";
export const Navbar = () => {
  const ServiceTabs = ["Agency", "Academy", "Advisory"];
  const [selectedServiceTabs, setSelectedServiceTabs] = useState(
    ServiceTabs[0]
  );

  const workwithusTabs = ["Work With Us", "Tours & Workshops"];
  const [selectedWorkWithUsTabs, setSelectedWorkWithUsTabs] = useState(
    workwithusTabs[0]
  );

  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-light-nav-top">
        <div className="home-navbar">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <div className="container-fluid">
              <NavLink className="navbar-brand d-flex align-items-end" to="/">
                <img className="logo-img" src={Logo} alt="" />
                <h6 className="ms-2 type-text">
                  <Typewriter
                    options={{
                      strings: ["Agency", "Academy", "Advisory"],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h6>
              </NavLink>
              <button
                className="navbar-toggler my-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse justify-content-end lh-lg"
                id="main_nav"
              >
                <ul className="navbar-nav p-3 p-md-0">
                  <li className="nav-item d-lg-block d-md-none d-none dropdown ktm-mega-menu">
                    <a
                      className="nav-link dropdown-toggle"
                      href="AboutUs"
                      data-bs-toggle="dropdown"
                    >
                      About Us
                    </a>
                    <div className="dropdown-menu mega-menu p-3">
                      <div className="container mega-menu-inner">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="">
                              <NavLink to="/AboutUs">
                                <div className="tabs border-b">
                                  <div className={"tab active"}>
                                    <span>Core Values</span>
                                    <span>
                                      <i className="fa-solid fa-arrow-right"></i>
                                    </span>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          </div>
                          <div className="col-lg-9 align-items-center">
                            <div className="col-lg-12">
                              <div className="padd-left">
                                <div className="row">
                                  <NavLink
                                    to="/AboutUs?id=ourphilosophy"
                                    className="text-decoration-none col-lg-4"
                                  >
                                    <p className="about-sub-heading">
                                      Our Philosophy
                                    </p>
                                  </NavLink>
                                  <NavLink
                                    to="/AboutUs?id=purposeofsocialeagle"
                                    className="text-decoration-none col-lg-4"
                                  >
                                    <p className="about-sub-heading">
                                      Purpose of Social Eagle
                                    </p>
                                  </NavLink>
                                  <NavLink
                                    to="/AboutUs?id=meetourteam"
                                    className="text-decoration-none col-lg-4"
                                  >
                                    <p className="about-sub-heading">
                                      Meet our Team
                                    </p>
                                  </NavLink>
                                  <NavLink
                                    to="/AboutUs?id=headlinesandhighlights"
                                    className="text-decoration-none col-lg-4"
                                  >
                                    <p className="about-sub-heading">
                                      Headlines & Highlights
                                    </p>
                                  </NavLink>
                                  <NavLink
                                    to="/AboutUs?id=empoweringchange"
                                    className="text-decoration-none col-lg-4"
                                  >
                                    <p className="about-sub-heading">
                                      Empowering Change
                                    </p>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item d-lg-none d-md-block  d-block">
                    <NavLink className="nav-link " to="/AboutUs">
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link " to="/CaseStudies">
                      Case Studies
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link " to="/Blogs">
                      Blogs
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown d-lg-block d-md-none d-none ktm-mega-menu">
                    <a
                      className="nav-link dropdown-toggle"
                      href="Services"
                      data-bs-toggle="dropdown"
                    >
                      Services
                    </a>
                    <div className="dropdown-menu mega-menu p-3">
                      <div className="container mega-menu-inner">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="">
                              <div className="tabs border-b">
                                {ServiceTabs.map((tab, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        selectedServiceTabs === tab
                                          ? "tab active  "
                                          : "tab "
                                      }
                                      onMouseOver={() => {
                                        setSelectedServiceTabs(tab);
                                      }}
                                      onClick={() => {
                                        if (
                                          selectedServiceTabs === "Advisory"
                                        ) {
                                          navigate("/Advisory");
                                        } else if (
                                          selectedServiceTabs === "Academy"
                                        ) {
                                          navigate("/Academy");
                                        } else if (
                                          selectedServiceTabs === "Agency"
                                        ) {
                                          navigate("/Agency");
                                        }
                                      }}
                                    >
                                      <span>{tab}</span>
                                      <span>
                                        <i className="fa-solid fa-arrow-right"></i>
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="padd-left">
                              {selectedServiceTabs === "Agency" && (
                                <NavLink
                                  to="/Agency"
                                  className="text-decoration-none"
                                >
                                  <div className="row">
                                    <div className="col-lg-7 my-auto">
                                      <h5 className="service-sub-head text-dark mb-3">
                                        Agency
                                      </h5>
                                      <h6 className="font-bold text-dark mb-2 text-uppercase">
                                        Creativity | Predictability |
                                        Scalability
                                      </h6>
                                      <p className="text-para-nav text-dark">
                                      We stand for what we commit and what we deliver
                                      </p>
                                    </div>
                                    <div className="col-lg-5">
                                      <div>
                                        <img
                                          className="w-100 h-auto"
                                          src={Navs1}
                                          alt=""
                                        ></img>
                                      </div>
                                    </div>
                                  </div>
                                </NavLink>
                              )}
                              {selectedServiceTabs === "Academy" && (
                                <NavLink
                                  to="/Academy"
                                  className="text-decoration-none"
                                >
                                  <div className="row">
                                    <div className="col-lg-7 my-auto">
                                      <h5 className="service-sub-head text-dark mb-3">
                                        Academy
                                      </h5>
                                      <h6 className="font-bold text-dark mb-2 text-uppercase">
                                        EMPOWER | ELEVATE | EMBRACE
                                      </h6>
                                      <p className=" text-para-nav text-dark">
                                        The Limitless Potential of Digital
                                        Marketing
                                      </p>
                                    </div>
                                    <div className="col-lg-5">
                                      <div>
                                        <img
                                          className="w-100 h-auto"
                                          src={Navs2}
                                          alt=""
                                        ></img>
                                      </div>
                                    </div>
                                  </div>
                                </NavLink>
                              )}
                              {selectedServiceTabs === "Advisory" && (
                                <NavLink
                                  to="/Advisory"
                                  className="text-decoration-none"
                                >
                                  <div className="row">
                                    <div className="col-lg-7 my-auto">
                                      <h5 className="service-sub-head text-dark mb-3">
                                        Advisory
                                      </h5>
                                      <h6 className="font-bold text-uppercase text-dark">
                                        Succeed More…  Suffer Less
                                      </h6>
                                      <p className=" text-para-nav text-dark">
                                        We take consulting to the next level
                                        with Battle-Tested and Proven Results
                                      </p>
                                    </div>
                                    <div className="col-lg-5">
                                      <div>
                                        <img
                                          className="w-100 h-auto"
                                          src={Navs3}
                                          alt=""
                                        ></img>
                                      </div>
                                    </div>
                                  </div>
                                </NavLink>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown  d-lg-none d-md-block  d-block">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Services
                    </a>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/Agency"
                        >
                          Agency
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/Academy"
                        >
                          Academy
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/Advisory"
                        >
                          Advisory
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink className="nav-link " to="/FreeLearning">
                      Free Learning
                    </NavLink>
                  </li> */}
                  <li className="nav-item dropdown d-lg-block d-md-none d-none ktm-mega-menu">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Work with us
                    </a>

                    <div className="dropdown-menu mega-menu p-3">
                      <div className="container mega-menu-inner">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="">
                              <div className="tabs border-b">
                                {workwithusTabs.map((tab, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        selectedWorkWithUsTabs === tab
                                          ? "tab active  "
                                          : "tab "
                                      }
                                      onMouseOver={() => {
                                        setSelectedWorkWithUsTabs(tab);
                                      }}
                                      onClick={() => {
                                        if (
                                          selectedWorkWithUsTabs ===
                                          "Work With Us"
                                        ) {
                                          navigate("/WorkWithUs");
                                        } else if (
                                          selectedWorkWithUsTabs ===
                                          "Tours & Workshops"
                                        ) {
                                          navigate("/ToursandWorkshops");
                                        }
                                      }}
                                    >
                                      <span>{tab}</span>
                                      <span>
                                        <i className="fa-solid fa-arrow-right"></i>
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9 my-auto">
                            {selectedWorkWithUsTabs === "Work With Us" && (
                              <NavLink
                                to="/WorkWithUs"
                                className="text-decoration-none "
                              >
                                <div className="padd-left">
                                  <div className="row">
                                    <NavLink
                                      to="/WorkWithUs?id=workculture"
                                      className="col-lg-4 text-decoration-none"
                                    >
                                      <p className="about-sub-heading">
                                        Work Culture
                                      </p>
                                    </NavLink>
                                    <NavLink
                                      className="col-lg-4 text-decoration-none"
                                      to="/WorkWithUs?id=companyvibe"
                                    >
                                      <p className="about-sub-heading">
                                        Company Vibe
                                      </p>
                                    </NavLink>
                                    <NavLink
                                      to="/WorkWithUs?id=whyjoinus"
                                      className="col-lg-4 text-decoration-none"
                                    >
                                      <p className="about-sub-heading">
                                        Why join us?
                                      </p>
                                    </NavLink>
                                    <NavLink
                                      to="/WorkWithUs?id=welookfor"
                                      className="col-lg-4 text-decoration-none"
                                    >
                                      <p className="about-sub-heading">
                                        8Cs we look for!
                                      </p>
                                    </NavLink>
                                    <NavLink
                                      to="/WorkWithUs?id=eagleattitude"
                                      className="col-lg-4 text-decoration-none"
                                    >
                                      <p className="about-sub-heading">
                                        Eagle Attitude
                                      </p>
                                    </NavLink>
                                  </div>
                                </div>
                              </NavLink>
                            )}
                            {selectedWorkWithUsTabs === "Tours & Workshops" && (
                              <NavLink
                                to="/ToursandWorkshops"
                                className="text-decoration-none "
                              >
                                <div className="padd-left"></div>
                              </NavLink>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown mb-3  d-lg-none d-md-block  d-block">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Work With Us
                    </a>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/WorkWithUs"
                        >
                          Work With Us
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="dropdown-item nav-link"
                          to="/ToursandWorkshops"
                        >
                          Tours & Workshops
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item my-auto">
                    <NavLink to="/ContactUs">
                      <button
                        type="button"
                        className="btn btn-primary contact-btn"
                      >
                        Contact us
                      </button>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
