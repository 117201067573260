import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import BIQuote from "../assets/images/bi_quote.webp";
import Profile from "../assets/images/profile.webp";
import Brian from "../assets/images/Brian.webp";
import suresh from "../assets/images/suresh.webp";
import Khanthini from "../assets/images/khanthini.webp";
import surersh2 from "../assets/images/surersh2.webp";
import iain from "../assets/images/iain.webp";
import jamaal from "../assets/images/jamaal.webp";
import scott from "../assets/images/scott.webp";

export const Testimonials = () => {
    const testimonial = [
        {
          image: Khanthini,
          heading: "Khanthini",
          work: "Psychologist - India",
          rating: 5,
          para: "Social Eagle helped me grow my follower base from 1000 to over 200,000 with less than 30 videos posted",
        },
        {
          image: Profile,
          heading: "Dominic",
          work: "Agency Owner (Singapore)",
          rating: 5,
          para: "We have Increased Client base and profitability by 267%",
        },
        {
          image: scott,
          heading: "Scott",
          work: "CEO, AGENSI",
          rating: 4,
          para: "We have sold our Digital Courses with 300% profit within 4 months.",
        },
        {
          image: Brian,
          heading: "Brian Mcbroom",
          work: "Chief Executive Coach City Officer (Denver City, USA)",
          rating: 5,
          para: "They helped me Grow my Facebook group and business by 169% by coaching CXO’s in 2 months.",
        },
        {
          image: iain,
          heading: "Iain",
          work: "Sales closer & COO Digital Closers",
          rating: 5,
          para: "They increased my appointment rate by 35% for our High-Ticket Sales closing business",
        },
        {
          image: jamaal,
          heading: "Jamaal",
          work: "Marketing Coach From UK",
          rating: 5,
          para: "Social Eagle increased my Coaching Business Result by 2X using their Proven Framework",
        },
        {
          image: suresh,
          heading: "Suresh Radhakrishnan",
          work: "CTO of Chaikanth and Leading Coach",
          rating: 5,
          para: "We got Consistent and Predictable Results for my Online Course",
        },
        // {
        //   image: surersh2,
        //   heading: "Suresh Balachander",
        //   work: "Business Coach - India",
        //   rating: 5,
        //   para: "Social Eagle helped me get 6X ROAS for my Digital Product",
        // },
      ];
  return (
    <div>
        <div className="py-3">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="1"
                  dots={false}
                  smartSpeed={2000}
                  nav={true}
                  margin={20}
                  // center={true}
                  autoplayTimeout={5000}
                  responsive={{
                    360: {
                      items: "1",
                    },
                    414: {
                      items: "1",
                    },
                    670: {
                      items: "2",
                    },
                    992: {
                      items: "2",
                    },
                    1200: {
                      items: "2",
                    },
                  }}
                >
                  {testimonial.map((item, index) => {
                    return (
                      <div className="case-study-card p-5" key={index}>
                        <img
                          className="bi-quote mb-2"
                          src={BIQuote}
                          alt=""
                        ></img>

                        <div>
                          <p className="casestudy-para case-para-home fs-6">
                            {item.para}
                          </p>
                        </div>
                        <div className="d-flex align-items-center mb-3 ">
                          {new Array(item.rating).fill(null).map((_, i) => (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 28 28"
                              fill="none"
                              key={i}
                            >
                              <path
                                d="M13.9987 20.1484L18.8404 23.0767C19.7271 23.6134 20.8121 22.8201 20.5787 21.8167L19.2954 16.3101L23.5771 12.6001C24.3587 11.9234 23.9387 10.6401 22.9121 10.5584L17.2771 10.0801L15.0721 4.87672C14.6754 3.93172 13.3221 3.93172 12.9254 4.87672L10.7204 10.0684L5.08541 10.5467C4.05874 10.6284 3.63874 11.9117 4.42041 12.5884L8.70208 16.2984L7.41874 21.8051C7.18541 22.8084 8.27041 23.6017 9.15708 23.0651L13.9987 20.1484Z"
                                fill="#FFA41B"
                              />
                            </svg>
                          ))}
                        </div>
                        <hr className="border-color-red" />
                        <div className="d-flex align-items-center">
                          <img
                            className="testi-img me-3"
                            src={item.image}
                            alt=""
                          ></img>
                          <div>
                            <h6 className="font-bold mb-1">{item.heading}</h6>
                            <p className="mb-0 testi-para ">{item.location}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
    </div>
  )
}
