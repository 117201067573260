import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { ColdEmailing } from "../Components/BlogDetails/ColdEmailing";

export const ColdEmailingSecrets = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <ColdEmailing />
      <Footer />
    </div>
  );
};
