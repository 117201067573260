import React, { useEffect, useState } from "react";
import Sec1 from "../../assets/images/sec1.webp";
import Sec2 from "../../assets/images/sec2.webp";
import Sec3 from "../../assets/images/sec3.webp";
import Sec4 from "../../assets/images/sec4.webp";
import Sec5 from "../../assets/images/sec5.webp";
import Sec6 from "../../assets/images/sec6.webp";
import Sec7 from "../../assets/images/sec7.webp";
import Tick from "../../assets/images/tick.webp";
import Look1 from "../../assets/images/look1.webp";
import Look2 from "../../assets/images/look2.webp";
import Look3 from "../../assets/images/look3.webp";
import Look4 from "../../assets/images/look4.webp";
import Look5 from "../../assets/images/look5.webp";
import Look6 from "../../assets/images/look6.webp";
import Look7 from "../../assets/images/look7.webp";
import Look8 from "../../assets/images/look8.webp";
import Data from "../../assets/images/eagle.webp";
import Phone from "../../assets/images/phone.webp";
import Mail from "../../assets/images/mail.webp";
import Career from "../../assets/images/careers.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useLocation } from "react-router-dom";
import WorkwithusModal from "./WorkwithusModal";

export const WorkWithUsContent = () => {
  const [open, setOpen] = useState();
  const myOption = [
    {
      para: "Our Space is a Temple of Energy.",
      number: "01",
    },
    {
      para: "A Culture of Joy - Always Expressed",
      number: "02",
    },
    {
      para: "As healthy a Workplace can get. ",
      number: "03",
    },
    {
      para: "Aligned Systems, Clear Processes and Structures. ",
      number: "04",
    },
    {
      para: "We are more than just a Team",
      number: "05",
    },
    {
      para: "Teams working Synergistically.  ",
      number: "06",
    },
    {
      para: "High Levels of Energy.",
      number: "07",
    },
    {
      para: "Transparent Communication",
      number: "08",
    },
    {
      para: "Relationships with Empathy",
      number: "09",
    },
    {
      para: "Oppurtunity to Learn & Shine.",
      number: "10",
    },
    {
      para: "Maximum Potential Enhancement / Personal Development.",
      number: "11",
    },
  ];
  const location = useLocation();

  useEffect(() => {
    const { search } = location;
    const containerId = search.substring(4); // Remove the leading slash
    const element = document.getElementById(containerId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  }, [location]);

  return (
    <div>
      <div className="work-cul my-5" id="workculture">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 border-end">
              <div className="mb-4 ps-lg-0 ps-3">
                <h3 className="color-sec-head font-bold ">Work Culture...</h3>
                <h2 className="section-heading mb-2">Ubuntu</h2>
                <h4 className="mb-0 font-bold">(noun)</h4>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ps-3 mb-4">
                <h4 className="text-secondary mb-3">
                  “We are human only through the humanity of others”
                </h4>
                <h4 className="text-secondary mb-3">
                  “We are people through other people”
                </h4>

                <h4 className="text-secondary mb-3">
                  “We cannot be fully human alone”
                </h4>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-4">
                <h5 className="text-secondary font-bold text-center">
                  “We are all connected and one can only grow and progress
                  through the growth and progression of others”
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="work-img  py-5">
        <div className="container-fluid">
          <div className="row align-items-end" id="funatwork">
            <div className="col-lg-2 mb-lg-0 mb-2 col-6">
              <div>
                <img className="w-100 h-auto boxshadow-wwh" src={Sec1}></img>
              </div>
            </div>
            <div className="col-lg-2 mb-lg-0 mb-2 col-6">
              <div>
                <img
                  className="w-100 h-auto boxshadow-wwh mb-3"
                  src={Sec2}
                ></img>
              </div>
              <div>
                <img className="w-100 h-auto boxshadow-wwh" src={Sec3}></img>
              </div>
            </div>
            <div className="col-lg-4 mb-lg-0 mb-2 col-12">
              <div>
                <img className="w-100 h-auto boxshadow-wwh" src={Sec4}></img>
              </div>
            </div>
            <div className="col-lg-2 mb-lg-0 mb-2 col-6">
              <div>
                <img
                  className="w-100 h-auto boxshadow-wwh mb-3"
                  src={Sec5}
                ></img>
                <img className="w-100 h-auto boxshadow-wwh " src={Sec6}></img>
              </div>
            </div>
            <div className="col-lg-2 mb-lg-0 mb-2 col-6">
              <img className="w-100 h-auto boxshadow-wwh " src={Sec7}></img>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 mt-lg-5 mt-3">
              <div className="content-wwh text-center">
                <h5 className="text-secondary ">
                  “With each new day in Africa, a gazelle wakes up knowing he
                  must outrun the fastest lion or perish. At the same time, a
                  lion stirs and stretches, knowing he must outrun the slowest
                  gazelle or starve”
                </h5>
                <h5 className="text-secondary">
                  “It is no different for the human race. Whether you consider
                  yourself a gazelle or a lion, you simply have to run faster
                  than others to survive”
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="company-vibe section-gap" id="companyvibe">
        <div className="container my-5">
          <div className="text-center">
            <h3 className="mb-2 text-white font-bold company-vibe-head">
              COMPANY VIBE
            </h3>
            <h1 className="banner-heading mb-4">We believe that</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="d-flex align-items-start mb-4">
                <img className="tick-img" src={Tick} alt=""></img>
                <h5 className="text-white lh-base mb-0">
                  When skilled individuals work together, they boost each
                  other's abilities.
                </h5>
              </div>
              <div className="d-flex align-items-start mb-4">
                <img className="tick-img" src={Tick} alt=""></img>
                <h5 className="text-white lh-base mb-0">
                  Success is like a forest fire.
                </h5>
              </div>
              <div className="d-flex align-items-start mb-4">
                <img className="tick-img" src={Tick} alt=""></img>
                <h5 className="text-white lh-base mb-0">
                  We value a culture where everyone has the freedom to express{" "}
                  themselves and takes responsibility for their actions.
                </h5>
              </div>
              <div className="d-flex align-items-start mb-4">
                <img className="tick-img" src={Tick} alt=""></img>
                <h5 className="text-white lh-base mb-0">
                  We're honest about our thoughts and strive to help one
                  another.
                </h5>
              </div>
              <div className="d-flex align-items-start mb-4">
                <img className="tick-img" src={Tick} alt=""></img>
                <h5 className="text-white lh-base mb-0">
                  Instead of enforcing rules, we guide based on understanding
                  the <br /> situation.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap" id="welookfor">
        <div className="container">
          <div>
            <h2 className="section-heading text-center">
              <span className="color-sec-head"> 8Cs </span> We Look For!
            </h2>
          </div>
          <div className="row ">
            <div className="col-lg-3 col-md-6 mb-4 ">
              <div>
                <div>
                  <div className="mb-2">
                    <img className="site-info-img" src={Look1} alt=""></img>
                  </div>
                  <div className="">
                    <h5 className="site-value">Common Sense</h5>
                    <h6 className="text-secondary lh-base">
                      Do You ask yourself real <br /> world questions.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <div>
                <div className="mb-2">
                  <img className="site-info-img" src={Look2} alt=""></img>
                </div>
                <div className="">
                  <h5 className="site-value">Communication</h5>
                  <h6 className="text-secondary lh-base">
                    We don’t watch Mega-serials, can you Communicate to the
                    Point.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 ">
              <div>
                <div className="mb-2">
                  <img className="site-info-img" src={Look3} alt=""></img>
                </div>
                <div className="">
                  <h5 className="site-value">Character</h5>
                  <h6 className="text-secondary lh-base">
                    What defines You as a <br /> Person.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 ">
              <div>
                <div className="mb-2">
                  <img className="site-info-img" src={Look4} alt=""></img>
                </div>
                <div className="">
                  <h5 className="site-value">Competence</h5>
                  <h6 className="text-secondary lh-base">
                    Do You thrive in Challenging <br /> situations.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4  ">
              <div>
                <div className="mb-2">
                  <img className="site-info-img" src={Look5} alt=""></img>
                </div>
                <div className="">
                  <h5 className="site-value">Consistency</h5>
                  <h6 className="text-secondary lh-base">
                    Can You show up on a <br /> Daily basis.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 ">
              <div className=" ">
                <div className="mb-2">
                  <img className="site-info-img" src={Look6} alt=""></img>
                </div>
                <div className="">
                  <h5 className="site-value">Computer Knowledge</h5>
                  <h6 className="text-secondary lh-base">
                    Do You have the Ability to learn the necessary tools to be
                    efficient.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4  ">
              <div>
                <div className="mb-2">
                  <img className="site-info-img" src={Look7} alt=""></img>
                </div>
                <div className="">
                  <h5 className="site-value">Creativity</h5>
                  <h6 className="text-secondary lh-base">
                    When it comes to thinking outside the box, do You ask “Where
                    is the box?”.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 ">
              <div className=" ">
                <div className="mb-2">
                  <img className="site-info-img" src={Look8} alt=""></img>
                </div>
                <div className="">
                  <h5 className="site-value">Commitment</h5>
                  <h6 className="text-secondary lh-base">
                    How ready You are to submit yourself to the task at hand.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap why-join-us" id="whyjoinus">
        <div className="container">
          <div>
            <h2 className="section-heading text-center mb-0">
              Why <span className="color-sec-head">join us?</span>
            </h2>
          </div>
          <div className=" ">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay={true}
              items="1"
              dots={true}
              smartSpeed={2000}
              nav={true}
              margin={20}
              center={true}
              autoplayTimeout={5000}
              responsive={{
                360: {
                  items: "1",
                },
                414: {
                  items: "1",
                },
                670: {
                  items: "2",
                },
                992: {
                  items: "3",
                },
                1200: {
                  items: "3",
                },
              }}
            >
              {myOption.map((item, index) => {
                return (
                  <div>
                    <div className="my-option-card">
                      <div className="service-red-bg pt-5 ">
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="height-para">
                              <h5 className="para-white fs-6 lh-base ">
                                {item.para}
                              </h5>
                              <hr className="red-half-line text-white"></hr>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <h1 className="number-wwu font-bold">
                              {item.number}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
      <div className="founder-section section-gap" id="eagleattitude">
        <div className="container pt-3">
          <div>
            <h2 className="section-heading text-center ">
              Eagle <span className="color-sec-head">Attitude</span>
            </h2>
            <h5 className="para-color text-center mb-4">
              If you have Grit and an Eagle Attitude, and if you have these
              Attributes,
            </h5>
          </div>
          <div className="row justify-content-center flex-direct-mob">
            <div className="col-lg-5 col-md-6">
              <div className="dimg-container">
                <img src={Data} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="ps-lg-4 ps-0">
                <ul>
                  <li>
                    <p className="founder-para">Willing to add Value </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Appreciating the benefits of Teamwork.{" "}
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Showing empathy towards one another.{" "}
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Open to practising Excellence.{" "}
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Willing to show Integrity to the Team and to Clients.{" "}
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      Open to embracing Transparency.{" "}
                    </p>
                  </li>
                </ul>
                <h5 className="founder-para mt-4 mb-3">
                  Simply put, if you possess these, we want YOU!
                </h5>
                <div className="center-aligned-head">
                  <button
                    type="button"
                    className="btn mt-3 mb-3 btn-primary contact-btn "
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="founder-section section-gap">
        <div className="container " id="internship">
          <div>
            <h2 className="section-heading text-center mb-3">
              Let's <span className="color-sec-head">Work Together</span>
            </h2>
            <h3 className="font-bold text-center mb-4">Get in Touch with Us</h3>
          </div>
        </div>
        <div className="workwithus-bg">
          <div className="container">
            <div className="py-4 mb-5">
              <div className="d-flex justify-content-center align-items-center mb-3">
                <img className="con-img me-2" src={Mail} alt=""></img>
                <h5 className=" mb-0 color-sec-head">flyhigh@socialeagle.in</h5>
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                <img className="con-img me-2" src={Phone} alt=""></img>
                <h5 className=" mb-0 color-sec-head">+91 8428421222</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container " id="currentopenings">
          <div className="row justify-content-center flex-direct-mob">
            <div className="col-lg-5 col-md-6 ">
              <div className="dimg-container">
                <img src={Career} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="mt-4 ps-lg-3 ps-0">
                <h4 className="font-bold  mb-4 ps-0">Careers:</h4>
                <ul className="list-unstyled">
                  <li>
                    <p className="founder-para">Fortune favours the bold. </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      At Social Eagle, growth isn't just a goal—it's a way of
                      life.{" "}
                    </p>
                  </li>
                  <li>
                    <p className="founder-para">
                      We're searching for individuals who thrive on solving
                      tough challenges alongside super smart people.{" "}
                    </p>
                  </li>
                </ul>
                <p className="founder-para ">
                  To join our team, please send a cover letter along with your
                  CV to: <br />{" "}
                  <a className="flyhigh" href="flyhigh@socialeagle.in">
                    {" "}
                    flyhigh@socialeagle.in
                  </a>{" "}
                  <br />
                  Or click the button below and send us the details.
                </p>
                <div className="center-aligned-head">
                  <button
                    type="button"
                    className="btn mt-4 mb-4 btn-primary contact-btn "
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WorkwithusModal
        open={open}
        setClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
