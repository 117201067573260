import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { AcademyBanner } from "../Components/Academy/AcademyBanner";
import { AcademyContent } from "../Components/Academy/AcademyContent";

export const Academy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <AcademyBanner />
      <AcademyContent />
      <Footer />
    </div>
  );
};
