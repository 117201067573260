import React, { useRef, useEffect, useState } from "react";
import Logo1 from "../../assets/images/logo-1.webp";
import Logo2 from "../../assets/images/logo-2.webp";
import Logo3 from "../../assets/images/logo-3.webp";
import Logo4 from "../../assets/images/logo-4.webp";
import Icon1 from "../../assets/images/icon-1.webp";
import Icon2 from "../../assets/images/icon-2.webp";
import Icon3 from "../../assets/images/icon-3.webp";
import Icon4 from "../../assets/images/icon-4.webp";
import Icon5 from "../../assets/images/icon-5.webp";
import Icon6 from "../../assets/images/icon-6.webp";
import S1 from "../../assets/images/s-1.webp";
import S2 from "../../assets/images/s-2.webp";
import S3 from "../../assets/images/s-3.webp";

import SICON1 from "../../assets/images/sicon-1.webp";
import SICON2 from "../../assets/images/sicon-2.webp";
import SICON3 from "../../assets/images/sicon-3.webp";
import A1 from "../../assets/images/a1.webp";
import A2 from "../../assets/images/a2.webp";
import A3 from "../../assets/images/a3.webp";
import CS1 from "../../assets/images/cs-1.webp";
import CS2 from "../../assets/images/cs-2.webp";
import CS3 from "../../assets/images/cs-3.webp";
import CS4 from "../../assets/images/cs-4.webp";
import DImg from "../../assets/images/d-img.webp";
import TeamImg from "../../assets/images/team-img.webp";
import IconQuate from "../../assets/images/icon-quote.webp";
import Grow from "../../assets/images/grow.webp";
import Map from "../../assets/images/map.webp";

import Data from "../../assets/images/data.webp";
import ArrowRight from "../../assets/images/arrow-right.webp";
import Step from "../../assets/images/step.webp";
import StepBottom from "../../assets/images/step-bottom.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CountUp from "react-countup";
import { Link, useNavigate } from "react-router-dom";
import { OurClientsLogo } from "../OurClientsLogo";
import { Testimonials } from "../Testimonials";

export const HomeContent = () => {
  const myOption = [
    {
      image: S1,
      paraHeading: "Hire us to do if for you",
      heading: "Agency",
      para: "We take care of everything end-to-end to get the best results using a Data-Driven and a Performance-Based approach",
      path: "/Agency",
    },
    {
      image: S2,
      paraHeading: "Learn how to do it yourself",
      heading: "Academy",
      para: "We help MSMEs and Freelancers learn the trade and create their own Team and Digital Ecosystem.",
      path: "/Academy",
    },
    {
      image: S3,
      paraHeading: "Let's do it together",
      heading: "Advisory",
      para: "We help Businesses save time by 2x and Grow by 10x with Battle-Tested and Proven Results.",
      path: "/Advisory",
    },
  ];
  const navigation = useNavigate();

  const awardSection = [
    {
      image: A1,
      para: "10 Best Social Media Marketing Startups 2023",
    },
    {
      image: A2,
      para: "Power Contributor ICT Academy of Tamil Nadu 2022",
    },
    {
      image: A3,
      para: "Emerging Social Media Company of the Year 2017",
    },
  ];

  const caseStudy = [
    {
      image: CS1,
      heading: "Techgyan",
      para: "The Goal was to acquire customers in order to market a summer program driven by an advanced learning management system to learn technical skills that would aid subscribers in their career development.",
      path: "/CaseStudies",
    },
    {
      image: CS2,
      heading: "ECG Academy",
      para: "The Goal was to gain customers in order to market the ECG Academy learning system to medical professionals.",
      path: "/CaseStudies",
    },
    {
      image: CS3,
      heading: "Skin Pot co",
      para: "The Goal was to acquire customers for their skin care physical product 'Breast and Bums Butter', so we campaigned for their best combo offer of 'buy two get one free.'",
      path: "/CaseStudies",
    },
    {
      image: CS4,
      heading: "YCB Academy",
      para: "The Goal was to acquire customers for a digital product in order to book qualified appointments for a weight loss program.",
      path: "/CaseStudies",
    },
  ];

  return (
    <div className="home-content">
      <div className="home-banner-bottom-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="bg-banner-bottom-content">
                <div className="row align-items-center py-lg-4 py-2 justify-content-center">
                  <div className="col-lg-2 col-md-2 col-6">
                    <img className="w-100 h-auto" src={Logo1} alt=""></img>
                  </div>
                  <div className="col-lg-2 col-md-2 col-6">
                    <img className="w-100 h-auto" src={Logo2} alt=""></img>
                  </div>
                  <div className="col-lg-2 col-md-2 col-6">
                    <img className="w-100 h-auto" src={Logo3} alt=""></img>
                  </div>
                  <div className="col-lg-2 col-md-2 col-6">
                    <img className="w-100 h-auto" src={Logo4} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-info" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row ">
            <div className="col-lg-4 col-md-4 col-6 mb-3 border-end">
              <div>
                <div className="site-card ">
                  <div>
                    <img className="site-info-img" src={Icon6} alt=""></img>
                  </div>
                  <div className="">
                    <h3 className="site-value">
                      <CountUp className="site-value" delay={2} end={9} />+
                    </h3>
                    <h6>Years in the Industry</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-6 mb-3 border-end">
              <div className="site-card ">
                <div>
                  <img className="site-info-img" src={Icon1} alt=""></img>
                </div>
                <div className="">
                  <h3 className="site-value">
                    $<CountUp className="site-value" delay={2} end={2} />
                    .5M+
                  </h3>
                  <h6>Spends</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-6 mb-3 ">
              <div className="site-card ">
                <div>
                  <img className="site-info-img" src={Icon2} alt=""></img>
                </div>
                <div className="">
                  <h3 className="site-value">
                    <CountUp className="site-value" delay={2} end={200} />+
                  </h3>
                  <h6>Clients</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-6 mb-3 border-end">
              <div className="site-card ">
                <div>
                  <img className="site-info-img" src={Icon3} alt=""></img>
                </div>
                <div className="">
                  <h3 className="site-value">
                    <CountUp className="site-value" delay={2} end={800} />
                    K+
                  </h3>
                  <h6>Leads Generated</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-6 mb-3 border-end ">
              <div className="site-card ">
                <div>
                  <img className="site-info-img" src={Icon4} alt=""></img>
                </div>
                <div className="">
                  <h3 className="site-value">
                    $<CountUp className="site-value" delay={2} end={17} />
                    M+
                  </h3>
                  <h6>Revenue Generated</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-6 mb-3 ">
              <div className="site-card ">
                <div>
                  <img className="site-info-img" src={Icon5} alt=""></img>
                </div>
                <div className="">
                  <h3 className="site-value">
                    <CountUp className="site-value" delay={2} end={16} />
                    K+
                  </h3>
                  <h6>People Trained</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wmo section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h2 className="section-heading text-center">
                What are <span className="color-sec-head">my options?</span>{" "}
              </h2>
              <h6 className="para-color lh-base text-center">
                At Social Eagle, we genuinely feel like we are an extended
                marketing arm for any brand that we associate with. We are
                careful in associating with any brand because we want to add
                tremendous value to the brand.
              </h6>
            </div>
          </div>
          <div className="row justify-content-center">
            {myOption.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="my-option-card">
                    <div>
                      <img className="opt-img" src={item.image} alt=""></img>
                    </div>
                    <div className="service-red-bg text-center">
                      <h6 className="para-white text-capitalize">
                        {item.paraHeading}
                      </h6>
                      <h1 className="heading-white   text-uppercase mb-3">
                        {item.heading}
                      </h1>
                      <div className="height-para">
                        <p className="para-white ">{item.para}</p>
                      </div>

                      <div className="text-center">
                        <Link to={item.path}>
                          <button
                            // onClick={() => {
                            //   navigation("/ContactUs");
                            // }}
                            type="button"
                            className="btn btn-white"
                          >
                            Get Started
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="our-clients section-gap"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container our-clients-inner-sec">
          <div className="row justify-content-center">
            <div className="col-lg-7 mb-5">
              <h2 className="section-heading text-white text-center">
                We’re grateful to work with{" "}
                <span className="color-sec-head">incredible clients</span>
              </h2>
            </div>
          </div>
          <div>
            <OurClientsLogo />
          </div>
        </div>
      </div>
      <div className="wmo section-gap">
        <div className="container" data-aos="fade-up" data-aos-delay="150">
          <div className="row ">
            <div className="col-lg-8">
              <h2 className="section-heading mb-0  ">
                Everything{" "}
                <span className="text-lowercase font-bold">we do is</span>{" "}
                <span className="color-sec-head">
                  quality based{" "}
                  <span className="text-lowercase text-dark font-bold">
                    {" "}
                    and
                  </span>{" "}
                  result driven
                </span>{" "}
              </h2>
            </div>
          </div>
          <div>
            <img className="w-100 h-auto every-do-img" src={Step} alt=""></img>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="">
                  <div className="text-center peme-4 mt-5">
                    <img className="step-icon" src={SICON1} alt=""></img>
                  </div>
                  <div className="py-4 peme-4 text-center">
                    <h5 className="font-extrabold text-uppercase">
                      Apply to work with us.
                    </h5>
                    <div className="">
                      <p className="">
                        We work with people who have <br />
                        <span className="font-bold">
                          {" "}
                          “The Eagle Spirit”{" "}
                        </span>{" "}
                        – Apply to see <br /> if there’s win-win synergy.
                      </p>
                    </div>
                  </div>
                  <div>
                    <img className="w-100 h-auto" src={StepBottom} alt=""></img>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="step-card-2">
                  <div className="text-center peme-4 mt-5">
                    <img className="step-icon" src={SICON2} alt=""></img>
                  </div>
                  <div className="py-4 peme-4 text-center">
                    <h5 className="font-extrabold text-uppercase">
                      See if we can work together.
                    </h5>
                    <div className="">
                      <p className="">
                        We’ll conduct a detailed <br /> analysis to meet your{" "}
                        <br /> Ambitious Goals{" "}
                      </p>
                    </div>
                  </div>
                  <div>
                    <img className="w-100 h-auto" src={StepBottom} alt=""></img>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="step-card-3">
                  <div className="text-center peme-4 mt-5">
                    <img className="step-icon" src={SICON3} alt=""></img>
                  </div>
                  <div className="py-4 peme-4 text-center">
                    <h5 className="font-extrabold text-uppercase">
                      We’ll take it from there.
                    </h5>
                    <div className="">
                      <p className="">
                        Leave it with us to raise
                        <br /> your standards.
                      </p>
                    </div>
                  </div>
                  <div>
                    <img className="w-100 h-auto" src={StepBottom} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="founder-section section-gap">
        <div className="container" data-aos="fade-up" data-aos-delay="150">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <h4 className="section-heading  fs-2">
                Message from <span className="color-sec-head">Founder</span>{" "}
              </h4>
              <p className="founder-para">
                Most of the businesses suffer because they don’t concentrate
                much on Marketing.
              </p>
              <p className="founder-para">
                If there is no Lead flow, there is no Cash flow. Most businesses
                depend on a single lead source. Creating multiple sources for
                lead generation is the predominant job of a Marketing team.
              </p>
              <p className="founder-para">
                We help you define your audience, refine your message and help
                you identify various platforms for you to leverage the maximum
                potential of the Digital Ecosystem.
              </p>
              <p className="founder-para">
                Only 3% of the audience are looking for a solution. The rest of
                the market has to be educated.
              </p>
              <p className="founder-para">
                People love to associate themselves with brands who are key
                influencers or differentiators.
              </p>
              <p className="founder-para">
                Creating a digital ecosystem like this is the way towards
                Digital Transformation and it’s the most important aspect of{" "}
                <span className="font-bold">Social Eagle </span>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="dimg-container">
                <img src={DImg} className="dimg" alt=""></img>
                <div className="relative-founder">
                  <div className="founder-red-bg">
                    <img className="icon-quote" src={IconQuate} alt=""></img>
                    <h6 className="text-white founder-lh fs-6 text-center">
                      <span className="bold-letter-founder text-nowrap text-uppercase fs-5">
                        “Marketing + data = new fuel"
                      </span>{" "}
                      <br />
                      Get the right attention. <br /> We take you to the escape
                      velocity.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="founder-section section-gap">
        <div className="container pt-5" data-aos="fade-up" data-aos-delay="150">
          <h4 className="color-sec-head d-lg-none d-md-none d-block mb-lg-5 mb-2 fs-2">
            Data plays an important role
          </h4>
          <div className="row">
            <div className="col-lg-6 col-md-6 my-auto ">
              <div className="dimg-container mb-2">
                <img src={Data} className="w-100 h-auto" alt=""></img>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto ">
              <h4 className="color-sec-head d-lg-block d-md-block d-none mb-lg-3  mb-2 fs-2">
                Data plays an important role
              </h4>
              <h5 className="font-bold mb-4">
                These are a few important things that we focus on in our Digital
                Marketing Agency.
              </h5>

              <div className="d-flex align-items-center mb-4">
                <img
                  className="bi-quote me-lg-4 me-1"
                  src={ArrowRight}
                  alt=""
                ></img>
                <p className="founder-para mb-0">Maintaining the data</p>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img
                  className="bi-quote me-lg-4 me-1"
                  src={ArrowRight}
                  alt=""
                ></img>
                <p className="founder-para mb-0">
                  Managing and nurturing the data
                </p>
              </div>
              <div className="d-flex align-items-center mb-4">
                <img
                  className="bi-quote me-lg-4 me-1"
                  src={ArrowRight}
                  alt=""
                ></img>
                <p className="founder-para mb-0">
                  Ensuring that they turn into customers and eventually into
                  raving fans
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wmo pt-lg-5 pt-0">
        <div className="container" data-aos="fade-up" data-aos-delay="150">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h2 className="section-heading text-center">
                Awards & <span className="color-sec-head">Recognitions</span>{" "}
              </h2>
              <h5 className="font-bold text-center">
                How the world rewards our extensive knowledge in multiple
                industries
              </h5>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row justify-content-center just-center mt-4">
                {awardSection.map((item, index) => {
                  return (
                    <div className="col-lg-4 col-md-6 mb-3 col-12" key={index}>
                      <div className="award-container">
                        <div>
                          <img
                            className="award-img"
                            src={item.image}
                            alt=""
                          ></img>
                        </div>
                        <div className="award-content text-center">
                          <div className="">
                            <h5 className="para-white lh-base fs-6 mb-0">
                              {item.para}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-section">
        <div
          className="container-fluid py-lg-5 py-2  px-4"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="row">
            <div className="col-lg-5">
              <h2 className="section-heading mt-lg-5 mt-0">
                Testimonials from{" "}
                <span className="color-sec-head">our clients</span>{" "}
              </h2>
              <p className="para-color ">
                Delivering top-notch service to our clients is great, but what
                really makes us happy is hearing how our work makes a difference
                for our clients and their businesses.
                <br /> We like to think of this as{" "}
                <span className="font-bold">The Eagle Impact.</span>
              </p>
            </div>
            <div className="col-lg-7">
              <Testimonials />
            </div>
          </div>
        </div>
      </div>

      <div className="founder-section section-gap">
        <div className="container pb-5" data-aos="fade-up" data-aos-delay="150">
          <div className="row">
            <div className="col-lg-6">
              <div className="dimg-container">
                <img src={TeamImg} className="teamimg" alt=""></img>
                <div className="relative-team">
                  <div className="team-white-bg">
                    <div className="d-flex mb-2 align-items-center">
                      <img className="grow-img" src={Grow} alt=""></img>
                      <h5 className=" font-bold fst-italic growing-fast-font text-capitalize fs-5 mb-0 text-center">
                        growing{" "}
                        <span className="color-sec-head fs-3 ">10X</span> is
                        Easier than{" "}
                        <span className="fs-3 color-sec-head ">2X</span>
                      </h5>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-lg-8 my-auto">
                          <p className="mb-0 team-para text-nowrap mb-0">
                            It is about Mindset + Data-Driven marketing
                          </p>
                        </div>
                        <div className="col-lg-4 col-4">
                          <img className="map-img" src={Map} alt=""></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 my-auto"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <h4 className="color-sec-head mb-4">About us</h4>
              <h4 className="section-heading  fs-2">
                Our marketing company is your partner in success.
              </h4>
              <p className="founder-para">
                As we carefully work with our clients through a Data-Driven and
                a Performance-Based approach, we use Analytics and Creativity to
                directly impact our Client’s Marketing, which improves Lead flow
                which directly improves Sales.
              </p>
              <p className="founder-para">
                We work with clients to develop personalized marketing
                strategies that is very specific to their business goals.
              </p>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    navigation("/ContactUs");
                  }}
                  className="btn mt-5 btn-primary contact-btn"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="case-study section-gap"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container-fluid px-lg-5 px-3 our-clients-inner-sec">
          <div className="row ">
            <div className="col-lg-7 mb-lg-4 mb-0">
              <h2 className="section-heading ">
                Featured <span className="color-sec-head">case studies</span>
              </h2>
            </div>
          </div>
          <div className="mb-3">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay={true}
              items="1"
              dots={false}
              smartSpeed={2000}
              nav={true}
              margin={20}
              // center={true}
              autoplayTimeout={5000}
              responsive={{
                360: {
                  items: "1",
                },
                414: {
                  items: "1",
                },
                670: {
                  items: "2",
                },
                992: {
                  items: "3",
                },
                1200: {
                  items: "4",
                },
              }}
            >
              {caseStudy.map((item, index) => {
                return (
                  <div className="case-study-card" key={index}>
                    <img
                      className="casestudy-img mb-4"
                      src={item.image}
                      alt=""
                    ></img>
                    <div>
                      <h5 className="font-bold mb-3">{item.heading}</h5>
                      <p className="casestudy-para">{item.para}</p>
                      <Link className="redirect-casestudy" to={item.path}>
                        View case study
                      </Link>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
          {/* <div className="text-center mt-5">
            <button type="button" className="btn  btn-primary contact-btn">
              View More
            </button>
          </div> */}
        </div>
      </div>
      <div
        className="wmo section-gap pt-lg-5 pt-0"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="container">
          <div
            data-aos="fade-up"
            data-aos-delay="150"
            className="row justify-content-center"
          >
            <div className="col-lg-7">
              <h2 className="section-heading text-center">
                FAQ<span className="color-sec-head">s</span>
              </h2>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="150" className="">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What does a digital marketing agency actually do?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    A good digital marketing agency will get to know your
                    business inside out. They will assess the traffic that your
                    website is getting and establish the best online marketing
                    platforms to invest in.
                    <br />
                    Part of a digital marketing agency’s strategy should involve
                    continual evaluation to ensure a client is maintaining the
                    perfect balance between their marketing spend and the
                    results they’re getting.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    How do I know that I’ll get a return on my investment?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Because we put our money where our mouth is and we deliver
                    more than what we commit to. Our Testimonials and Social
                    proofs show how we help our customers and businesses.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Do you work with big companies or small companies?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    If you’re serious about scaling your operation, dominating
                    your market, we can work with you. If you understand the
                    value of spending money to make more money, we can work
                    together. If you want to work with an A team to smash your
                    growth goals and make ROI, then we can work with you. It
                    doesn’t matter if you’re big or small.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Why is Social Eagle the best Digital Marketing Agency?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div>
                      Why are Cakes so delicious? Why is Water wet? But
                      seriously, we don’t want to spam you with our Testimonials
                      but let’s just say Marketers lie, numbers don’t. So
                      instead of trying to convince you that we are the best
                      Digital Marketing Agency around… Here are the stats.{" "}
                    </div>
                    <div>
                      <br></br>
                      <ul>
                        <li>9+ years of experience</li>
                        <li> 200+ clients</li>
                        <li> 800k+ leads generated</li>
                        <li> $16M+ revenue generated</li>
                        <li>16000+ people mentored</li>
                        <li>
                          Tamilnadu’s only Digital Marketing Agency which is AI
                          powered, 100% hands-on, Agency based and supported by
                          who’s who of the industry.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={() => {
                navigation("/ContactUs");
              }}
              className="btn mt-5 btn-primary contact-btn"
            >
              Fly with us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
