import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { ScopeofDM } from "../Components/BlogDetails/ScopeofDM";

export const ScopeofDigitalMarketing = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <ScopeofDM />
      <Footer />
    </div>
  );
};
