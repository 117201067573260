import React from "react";
import BlogImg3 from "../../assets/images/blog-img3.webp";
import { Link } from "react-router-dom";
export const LawofWastedEffortsContent = () => {
  return (
    <div>
      <div>
        <div
          className="bg-video-wrap2 blog-details-banner"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="home-video-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <h1 className="banner-heading mb-2 text-uppercase">
                    The Law of Wasted Efforts
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-5 section-gap"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="container">
            <div className="row ">
              <div className="col-lg-12">
                <div className="blog-content ">
                  <div className="row">
                    <div className="col-lg-8">
                      <img src={BlogImg3} className="w-100 h-auto"></img>
                    </div>
                  </div>
                  <div className="d-flex my-3 align-items-center">
                    <span className="me-2">
                      <i class="fa-regular text-secondary fa-calendar-days"></i>
                    </span>
                    <p className="text-secondary mb-0">12 Aug 2022</p>
                  </div>
                  <h2 className="font-bold mb-4">
                    The Law of Wasted Efforts
                  </h2>
                  <div className="">
                    <p>
                    🦁Lions only succeed in a quarter of their hunting attempts — which means they fail in 75% of their attempts and succeed in only 25% of them.
                    </p>
                    <p>Despite this small percentage shared by most predators, they don't despair in their pursuit and hunting attempts.</p>
                    <p>The main reason for this is not because of hunger as some might think, but it is the understanding of the “Law of Wasted Efforts” that has been instinctively built into animals, a law in which nature is governed.</p>
                    <p>👉 Half of the eggs of fish are eaten...</p>
                    <p>👉 Half of the baby bears die before puberty...</p>
                    <p>👉 Most of the world's rains fall in oceans...</p>
                    <p>👉 and most of the seeds of trees are eaten by birds.</p>
                    <p>Scientists have found that animals, trees & other forces of nature are more receptive to the law of "wasted efforts".</p>
                    <p>🙀🕺 Only humans think that the lack of success in a few attempts is a failure... but the truth is that:</p>
                    <p>🔥We only fail when we "stop trying"🔥</p>
                    <p>Success is not to have a life free of pitfalls and falls... but success is to walk over your mistakes and go beyond every stage where your efforts were wasted looking forward to the next stage.</p>
                    <p>SO:</p>
                    <p>🙌 Continue all over again.</p>
                    <p>🙅🏻‍♂️ Never stop at any stage of difficulty.</p>
                    <p>🏃🏻‍♂️ Keep moving, keep thinking, keep trying. </p>
                    <p>And remember:</p>
                    <p>𝙏𝙝𝙚𝙧𝙚 𝙞𝙨 𝙣𝙤 𝙛𝙖𝙞𝙡𝙪𝙧𝙚 𝙤𝙣𝙡𝙮 𝙛𝙚𝙚𝙙𝙗𝙖𝙘𝙠!!</p>
                    <p>Go out and collect as many as possible so you can build your comeback from what you have been learning🔥</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-gap text-center">
          <Link to="/ContactUs">
            <button type="button" className="btn btn-primary contact-btn">
              Get In Touch
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
