import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import C1 from "../assets/images/c1.webp";
import C2 from "../assets/images/c2.webp";
import C3 from "../assets/images/c3.webp";
import C4 from "../assets/images/c4.webp";
import C5 from "../assets/images/c5.webp";
import C6 from "../assets/images/c6.webp";
import C7 from "../assets/images/c7.webp";
import C8 from "../assets/images/c8.webp";
import C9 from "../assets/images/c9.webp";
import C10 from "../assets/images/c10.webp";
import C11 from "../assets/images/c11.webp";
import C12 from "../assets/images/c12.webp";
import C13 from "../assets/images/c13.webp";
import C14 from "../assets/images/c14.webp";
import C15 from "../assets/images/c15.webp";
import C16 from "../assets/images/c16.webp";
import C17 from "../assets/images/c17.webp";
import C18 from "../assets/images/c18.webp";
import C19 from "../assets/images/c19.webp";
import C20 from "../assets/images/c20.webp";
import C21 from "../assets/images/c21.webp";
import C22 from "../assets/images/c22.webp";
import C23 from "../assets/images/c23.webp";
import C24 from "../assets/images/c24.webp";
import C25 from "../assets/images/c25.webp";
import C26 from "../assets/images/c26.webp";
import C27 from "../assets/images/c27.webp";
import C28 from "../assets/images/c28.webp";
import C29 from "../assets/images/c29.webp";
import C30 from "../assets/images/c30.webp";
import C31 from "../assets/images/c31.webp";
import C32 from "../assets/images/c32.webp";

export const OurClientsLogo = () => {
  return (
    <div>
      <div>
        <div className="mb-3">
          <OwlCarousel
            className="owl-theme"
            loop
            autoplay={true}
            items="1"
            dots={false}
            smartSpeed={2000}
            nav={false}
            margin={20}
            center={true}
            autoplayTimeout={2000}
            responsive={{
              360: {
                items: "2",
              },
              414: {
                items: "2",
              },
              670: {
                items: "3",
              },
              992: {
                items: "3",
              },
              1200: {
                items: "5",
              },
            }}
          >
            <div>
              <img className="client-img" src={C1} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C2} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C3} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C4} alt=""></img>
            </div>
            {/* 
                <div>
                  <img className="client-img" src={C5} alt=""></img>
                </div>
                */}
            <div>
              <img className="client-img" src={C6} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C7} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C8} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C9} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C10} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C11} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C12} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C13} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C14} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C15} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C16} alt=""></img>
            </div>
          </OwlCarousel>
        </div>
        <div>
          <OwlCarousel
            className="owl-theme"
            loop
            autoplay={true}
            items="1"
            dots={false}
            smartSpeed={2000}
            nav={false}
            margin={20}
            // center={true}
            autoplayTimeout={2000}
            responsive={{
              360: {
                items: "2",
              },
              414: {
                items: "2",
              },
              670: {
                items: "3",
              },
              992: {
                items: "3",
              },
              1200: {
                items: "5",
              },
            }}
          >
            <div>
              <img className="client-img" src={C17} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C18} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C19} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C20} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C21} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C22} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C23} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C24} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C25} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C26} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C27} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C28} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C29} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C30} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C31} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C32} alt=""></img>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}
