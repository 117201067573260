import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { WorkWithUsBanner } from "../Components/WorkWithUs/WorkWithUsBanner";
import { WorkWithUsContent } from "../Components/WorkWithUs/WorkWithUsContent";
import { useLocation } from "react-router-dom";

export const WorkWithUs = () => {
  const location = useLocation();
  useEffect(() => {
    const { search } = location;
    if (!search) {
      window.scroll(0, 0);
    }
  }, []);
  return (
    <div>
      <Navbar />
      <WorkWithUsBanner />
      <WorkWithUsContent />
      <Footer />
    </div>
  );
};
