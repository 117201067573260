import React, { useState } from "react";
import CustomModal from "../CustomModal";
import ThankYouModal from "../ThankYouModal";
import axios from "axios";
const WorkwithusModal = ({ open, setClose }) => {
  const [thankYouModal, setThankYouModal] = useState(false);
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = async () => {
    let flag = true;
    let validate = {};
    setLoading(true);
    const currentDate = new Date();

    if (!data.name?.length > 0) {
      flag = false;
      validate.name = "Name is Required";
    }
    if (
      !(
        data.phoneNumber?.length === 13 ||
        data.phoneNumber?.length === 12 ||
        data.phoneNumber?.length === 10
      )
    ) {
      flag = false;
      validate.phoneNumber = "Phone Number is Required";
    }
    if (!data.emailId?.length > 0) {
      flag = false;
      validate.emailId = "Email is Required";
    }
    if (!data.subject?.length > 0) {
      flag = false;
      validate.subject = "Subject is Required";
    }

    if (flag) {
      try {
        let inputValue = {
          Name: data.name,
          "Phone Number": data.phoneNumber,
          "Email Id": data.emailId,
          Subject: data.subject,
          Comments: data.message,
          Date: currentDate.toDateString(),
        };

        let form_data = new FormData();
        for (let key in inputValue) {
          form_data.append(key, inputValue[key]);
        }


        const Payload = [
          {
            Attribute: "FirstName",
            Value: data.name,
          },
          {
            Attribute: "EmailAddress",
            Value: data.emailId,
          },
          {
            Attribute: "Phone",
            Value: data.phoneNumber,
          },
          {
            Attribute: "Role",
            Value: data.role,
          },
          {
            Attribute: "Subject",
            Value: data.subject,
          },
          {
            Attribute: "Message",
            Value: data.message,
          },
          {
            Attribute: "Source",
            Value: "Web-WorkWithUs",
          }
        ];

        try{
          const response = await axios.post(
            "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r6bb21b3cf1518343d1981ce6646b844f&secretKey=d58ccce3a29496917e48f84d2f40d31061c6b781",
            Payload,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
        }catch(e){
          
        }

        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbxWe0s4yN9mNVzSaCSYNGG5FsOcne33SuqsuhtKz9A-Xwik_3QSQK1dOD7Tgmnn0BgUfA/exec", // Replace with the URL from the deployed web app
          {
            method: "POST",
            body: form_data,
          }
        );

        await response.text();
        setLoading(false);
        setClose();
        setValidation({})
        setData({});
        setThankYouModal(true);
      } catch (error) {
        setLoading(false);
        console.error("Error writing to Google Sheets:", error);
      }
    }else{
      setLoading(false);
      setValidation(validate)
    }
    
  };

  const setFieldData = (e, key) => {
    setData({ ...data, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  return (
    <div>
      <CustomModal
        open={open}
        onClickOutside={() => {
          setClose(false);
          setValidation({});
        }}
      >
        <div className="modal-content contact-container w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setClose(false);
                  setValidation({});
                }}
              >
                <i class="fa-solid fa-xmark"></i>
              </span>
              <div className="row d-flex align-items-center justify-content-center  my-4">
                <div className="container px-lg-5 px-3 ">
                  <div className="row justify-content-center  height-modal ">
                    <h4 className="text-center font-bold">Send us a Message</h4>
                    <div className="col-lg-12 justify-content-center">
                      <div className="mb-2">
                        <label htmlFor="name" className="font-bold mb-2">
                          Name
                        </label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setFieldData(e.target.value, "name");
                          }}
                          className="form-control"
                          id="name"
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.name}
                        </p>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="phno" className="font-bold mb-2">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phno"
                          onChange={(e) => {
                            setFieldData(e.target.value, "phoneNumber");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.phoneNumber}
                        </p>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="email" className="font-bold mb-2">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          onChange={(e) => {
                            setFieldData(e.target.value, "emailId");
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.emailId}
                        </p>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="email" className="font-bold mb-2">
                          Job Role
                        </label>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setFieldData(e.target.value, "role");
                          }}
                        >
                          <option selected>Select Position</option>
                          <option value="Graphic Designer">
                            Graphic Designer
                          </option>
                          <option value="Video Editor">Video Editor</option>
                          <option value="Digital Marketing Executive">
                            Digital Marketing Executive
                          </option>
                          <option value="Sales Expert">Sales Expert</option>
                        </select>
                        {/* <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.emailId}
                        </p> */}
                      </div>
                      <div className="mb-2">
                        <label htmlFor="subject" className="font-bold mb-2">
                          Subject
                        </label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setFieldData(e.target.value, "subject");
                          }}
                          className="form-control"
                          id="subject"
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          {validation.subject}
                        </p>
                      </div>
                      <div className="mb-2">
                        <label htmlFor="message" className="font-bold mb-2">
                          Add a Message
                        </label>
                        <textarea
                          onChange={(e) => {
                            setFieldData(e.target.value, "message");
                          }}
                          type="text"
                          className="form-control"
                          id="message"
                          rows="4"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn  mt-3  w-100 btn-primary contact-btn"
                      onClick={(inputIndex) => validate(inputIndex)}
                    >
                      {loading ? (
                        <i className="fa fa-circle-notch fa-solid"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ThankYouModal
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
    </div>
  );
};

export default WorkwithusModal;
