import React, { useState } from "react";
import Phone from "../../assets/images/phone.webp";
import Location from "../../assets/images/location.webp";
import Mail from "../../assets/images/mail.webp";
import ThankYouModal from "../ThankYouModal";
import axios from "axios";
const Contact = () => {
  const [thankYouModal, setThankYouModal] = useState(false);
  const SERVICE_TYPES = [
    "Social Media Marketing",
    "Performance marketing",
    "SEO and content marketing",
    "Video creation and marketing",
    "Web & Mobile App Development",
    "Personal Branding",
    "Influencer Marketing",
    "Branding & Identity",
    "Landing Pages",
    "Marketing Automation",
  ];
  const [data, setData] = useState({});
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setCheckedItems([...checkedItems, name]); // Add the title to the array
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== name)); // Remove the title from the array
    }
    if (validation.serviceType) delete validation.serviceType;
  };
  const validate = async (e) => {
    e.preventDefault();

    let flag = true,
      validationData = {};
    setLoading(true);
    const currentDate = new Date();

    if (!data.name?.length > 0) {
      flag = false;
      validationData.name = "Name is Required";
    }
    if (
      !(
        data.phoneNumber?.length === 13 ||
        data.phoneNumber?.length === 12 ||
        data.phoneNumber?.length === 10
      )
    ) {
      flag = false;
      validationData.phoneNumber = "Phone Number is Required";
    }
    if (!data.emailId?.length > 0) {
      flag = false;
      validationData.emailId = "Email is Required";
    }
    if (!data.companyName?.length > 0) {
      flag = false;
      validationData.companyName = "Company Name is Required";
    }
    if (!data.websiteUrl?.length > 0) {
      flag = false;
      validationData.websiteUrl = "Website Url is Required";
    }
    if (!data.price?.length > 0) {
      flag = false;
      validationData.price = "Avg. monthly marketing is Required";
    }

    if (!checkedItems.length) {
      flag = false;
      validationData.serviceType = "Service Type is Required";
    }
    setValidation(validationData);
    if (flag) {
      try {
        console.log(checkedItems);
        let inputValue = {
          Name: data.name,
          "Phone Number": data.phoneNumber,
          "Email Id": data.emailId,
          "Company Name": data.companyName,
          "Company Website": data.websiteUrl,
          "Average Monthly Marketing": data.price,
          "Service Type": checkedItems.toString(),
          Comments: data?.message ?? "",
          Date: currentDate.toDateString(),
        };
        const Payload = [
          {
            Attribute: "FirstName",
            Value: data.name,
          },
          {
            Attribute: "EmailAddress",
            Value: data.emailId,
          },
          {
            Attribute: "Phone",
            Value: data.phoneNumber,
          },
          {
            Attribute: "Company Name",
            Value: data.companyName,
          },
          {
            Attribute: "Company Website",
            Value: data.websiteUrl,
          },
          {
            Attribute: "Average Monthly Marketing",
            Value: data.price,
          },
          {
            Attribute: "Service Type",
            Value: checkedItems.toString(),
          },
          {
            Attribute: "Comments",
            Value: data?.message ?? "",
          },
          {
            Attribute: "Source",
            Value: "Web-ContactUs",
          },
        ];

        try {
          const response = await axios.post(
            "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r6bb21b3cf1518343d1981ce6646b844f&secretKey=d58ccce3a29496917e48f84d2f40d31061c6b781",
            Payload,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
        } catch (e) { }
        let form_data = new FormData();
        for (let key in inputValue) {
          form_data.append(key, inputValue[key]);
        }
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbzY8xT0MLOUXnVwz2laCuMh1bHGieCI2DnBbz4H_jK7jwZTsvGnJijOw26GD9s94PY6EA/exec", // Replace with the URL from the deployed web app
          {
            method: "POST",
            body: form_data,
          }
        );

        const result = await response.text();
        setLoading(false);
        setData({});
        setThankYouModal(true);
        setCheckedItems([]);
        e.target.reset();
      } catch (error) {
        setLoading(false);
        console.error("Error writing to Google Sheets:", error);
      }
    } else {
      setLoading(false);
    }
  };
  const setFieldData = (e, key) => {
    setData({ ...data, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  return (
    <div className="">
      <ThankYouModal
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
      <div className=" container py-lg-5 py-md-3 py-2">
        <div
          className="contact-container my-4"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div className="row">
            <div className="col-lg-4 col-md-6 border-end">
              <div className="inner-container-contact">
                <h4 className="font-bold mb-4">Contact Info</h4>
                <div className="d-flex align-items-center mb-4">
                  <img className="con-img me-2" src={Phone} alt=""></img>
                  <p className=" mb-0">+91 8428421222</p>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <img className="con-img me-2" src={Mail} alt=""></img>
                  <p className=" mb-0">flyhigh@socialeagle.in</p>
                </div>
                <div className="d-flex align-items-start mb-4">
                  <img className="con-img me-2" src={Location} alt=""></img>
                  <p className=" mb-0">
                    Office Address:<br />
                    <span className="font-bold">Social Eagle Academy</span>
                    <br />No. 14-16, Global Hospital Road Junction,
                    Indira Priyadarshini Nagar,
                    Opp. ICICI Bank,<br />
                    Perumbakkam,<br />
                    Chennai,<br />
                    Tamil Nadu 600100
                  </p>
                </div>
                {/* <div className="d-flex align-items-start mb-4">
                  <img className="con-img me-2" src={Location} alt=""></img>
                  <p className=" mb-0">
                    P305, Oxygen by Urban Tree, Pushkin Street, Gandhi Nagar,
                    Perumbakkam, Chennai – 600100
                  </p>
                </div> */}
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <form
                onSubmit={(e) => {
                  validate(e);
                }}
                className="inner-container-contact"
              >
                <h4 className="font-bold mb-4">I’m Interested in</h4>
                <div className="row mb-4">
                  {SERVICE_TYPES.map((v) => {
                    return (
                      <div key={v} className="col-lg-6 col-md-12 mb-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={v}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label" htmlFor={v}>
                            {v}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                  <p
                    style={{
                      color: "red",
                      marginBottom: "2px",
                      fontSize: "14px",
                    }}
                  >
                    {validation.serviceType}
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setFieldData(e.target.value, "name");
                        }}
                        placeholder="Full Name"
                      />
                      <p
                        style={{
                          color: "red",
                          marginBottom: "2px",
                          fontSize: "14px",
                        }}
                      >
                        {validation.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        onChange={(e) => {
                          setFieldData(e.target.value, "phoneNumber");
                        }}
                        className="form-control"
                        placeholder="Mobile Number"
                      />
                      <p
                        style={{
                          color: "red",
                          marginBottom: "2px",
                          fontSize: "14px",
                        }}
                      >
                        {validation.phoneNumber}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input
                        type="email"
                        onChange={(e) => {
                          setFieldData(e.target.value, "emailId");
                        }}
                        className="form-control"
                        placeholder="Email"
                      />
                      <p
                        style={{
                          color: "red",
                          marginBottom: "2px",
                          fontSize: "14px",
                        }}
                      >
                        {validation.emailId}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setFieldData(e.target.value, "companyName");
                        }}
                        placeholder="Company Name"
                      />
                      <p
                        style={{
                          color: "red",
                          marginBottom: "2px",
                          fontSize: "14px",
                        }}
                      >
                        {validation.companyName}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Avg. Monthly Marketing Budget"
                        onChange={(e) => {
                          setFieldData(e.target.value, "price");
                        }}
                      />
                      <p
                        style={{
                          color: "red",
                          marginBottom: "2px",
                          fontSize: "14px",
                        }}
                      >
                        {validation.price}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setFieldData(e.target.value, "websiteUrl");
                        }}
                        placeholder="Website URL"
                      />
                      <p
                        style={{
                          color: "red",
                          marginBottom: "2px",
                          fontSize: "14px",
                        }}
                      >
                        {validation.websiteUrl}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          onChange={(e) => {
                            setFieldData(e.target.value, "message");
                          }}
                          rows="3"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      className="btn  mt-5  w-auto btn-primary contact-btn"
                    >
                      {loading ? (
                        <i className="fa fa-circle-notch fa-solid"></i>
                      ) : (
                        "Send Message"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="" data-aos="fade-up" data-aos-delay="150">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.039011132825!2d80.20557927507531!3d12.905213087404057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d14f00995f7%3A0xb37cd82fd02a1480!2sSocial%20Eagle%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1703752628089!5m2!1sen!2sin"
          className="iframe-contact"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
