import React from "react";
import BlogImg1 from "../../assets/images/blog-img1.webp";
import { Link } from "react-router-dom";
export const RecessionContent = () => {
  return (
    <div>
      <div
        className="bg-video-wrap2 blog-details-banner"
        data-aos="fade-up"
        data-aos-delay="150"
      >
        <div className="home-video-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="banner-heading mb-2 text-uppercase">
                The Recession is Beneficial! 
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 section-gap" data-aos="fade-up" data-aos-delay="150">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className="blog-content ">
                <div className="row">
                  <div className="col-lg-8">
                    <img src={BlogImg1} className="w-100 h-auto"></img>
                  </div>
                </div>
                <div className="d-flex my-3 align-items-center">
                  <span className="me-2">
                    <i class="fa-regular text-secondary fa-calendar-days"></i>
                  </span>
                  <p className="text-secondary mb-0">12 Aug 2022</p>
                </div>
                <h2 className="font-bold mb-4">The Recession is Beneficial! 🤩</h2>
                <div className="">
                  <p>Isn't it shocking...? 😳</p>
                  <p>
                    There are, however, many benefits associated with …...
                    recessions...
                  </p>
                  <p>
                    There are, however, many benefits associated with recessions
                    compared to their negative effects!{" "}
                  </p>
                  <p>
                    The fear of a recession is widespread, but what does a
                    recession actually mean?
                  </p>
                  <p>
                    A recession is actually a period of negative economic
                    growth. A recession results in unemployment, wage declines,
                    and falls in the value of homes and other assets.
                  </p>
                  <p>
                    Although all businesses will experience turbulence during
                    the recession, only a few may benefit!
                  </p>
                  <p>
                    Here I have listed 10 benefits that the recession offers us.
                  </p>
                  <p>
                    ✅ You can buy anything cheaper because of the recession,
                    including heavy machinery, stocks, homes, and land
                  </p>
                  <p>
                    ✅ Hiring becomes cheaper, and employees are easily
                    available, but the right person must be chosen!
                  </p>
                  <p>
                    ✅ Starting a business will become more affordable, and
                    people who lose their jobs will probably start their own!
                  </p>
                  <p>
                    ✅ Purchasing items becomes easier, and credit purchases aid
                    in business promotion!
                  </p>
                  <p>
                    ✅ Because inflation remains flat, prices may remain stable
                    or even fall!
                  </p>
                  <p>
                    ✅ Due to big corporations' unpredictable nature, you will
                    experience less competition during a recession.
                  </p>
                  <p>
                    ✅ As people choose to repair and reuse old products instead
                    of buying all-new ones, maintenance businesses will see an
                    increase in their customer base.
                  </p>
                  <p>
                    ✅ Investing in your startup will be easy as the price of
                    stocks and interest rates are low.
                  </p>
                  <p>
                    ✅ Successfully running a business during a recession will
                    increase your reputation.
                  </p>
                  <p>
                    ✅ Identifies good and bad businesses, as only the latter
                    will survive the current economic crisis.
                  </p>
                  <p>
                    There are even situations where the biggest companies have
                    overcome previous recessions!{" "}
                  </p>
                  <p>
                    🔥 In the middle of the 1970s, when the recession hit,
                    Microsoft was launched. It quickly became very successful,
                    and by 1981, it had formed a partnership with IBM.{" "}
                  </p>
                  <p>
                    🔥 During the 2008 recession, Netflix introduced new
                    innovations with several tie-ups and triumphed! Back then,
                    Netflix was not such a huge company.
                  </p>
                  <p>
                    🔥 After seeing a 90% decline in the value of its stock
                    between 1999 and 2001, Amazon made a comeback and weathered
                    the recession.
                  </p>
                  <p>
                    🔥 Apple's iPod launch in 2001 was successful despite the
                    recession.
                  </p>
                  <p>And there's more to come!</p>
                  <p>
                    So the recession is going to be super beneficial! Stick to
                    the benefits and let's get over the upcoming recession!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gap text-center">
          <Link to="/ContactUs">
            <button type="button" className="btn btn-primary contact-btn">
              Get In Touch
            </button>
          </Link>
        </div>
    </div>
  );
};
