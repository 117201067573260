import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { PillarsofDM } from "../Components/BlogDetails/PillarsofDM";

export const SixPillarsofDigitalMarketing = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <PillarsofDM />
      <Footer />
    </div>
  );
};
