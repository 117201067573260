import React, { useEffect } from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { DMC12in1 } from "../Components/BlogDetails/DMC12in1";

export const TheCompleteDigitalMarketingCourse12CoursesIn1 = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <DMC12in1 />
      <Footer />
    </div>
  );
};
